<template>
    <div v-if="isVisible" class="mask"></div>
    <div v-if="isVisible" class="app_user">
        <img class="close_big" src="../assets/image/close_big.png" alt="" @click="hideUser" >
      <div class="user_profile">
        <div class="user_profile_proit" @click="uploadImage">
          <el-avatar v-if="!is_show_proit" :size="size" :style="{ backgroundColor: backgroundColor }">
            {{ proit }}
          </el-avatar>
          <img v-if="is_show_proit" :src="proit" alt="" />
          <img class="pen_white" src="../assets/image/pen_white.png" alt="" />
        </div>
        <div class="user_profile_nickname" @click="showNickname" >
          <p>{{ nickname }}</p>
          <img src="../assets/image/pen_color.png" alt="" />
        </div>
        <div class="user_profile_point">
          <img src="../assets/image/coin.png" alt="" />
          <p>{{ formatNumber(point) }}</p>
        </div>
      </div>
      <div class="user_profile_buttons">
        <div class="user_profile_button" @click="showUserPass" >
                <img src="../assets/image/login_pass.png" alt="">
                <p>{{ $t('login_pass') }}</p>
                <img class="right_img" src="../assets/image/right.png" alt="">
            </div>
            <div class="user_profile_button" @click="showWithPass" >
                <img src="../assets/image/withraw_pass.png" alt="">
                <p>{{ $t('withdrawal_password') }}</p>
                <img class="right_img" src="../assets/image/right.png" alt="">
            </div>
            <div class="user_profile_button"  @click="share()" >
                <img src="../assets/image/user_share.png" alt="">
                <p>{{ $t('personal_invitation_code') }}</p>
                <img class="right_img" src="../assets/image/right.png" alt="">
            </div>
            <div class="logout" @click="logout" >
              {{ $t('logout') }}
            </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  export default {
    data() {
      var userData = JSON.parse(sessionStorage.getItem("user"));
      var proitObject = {};
      proitObject.backgroundColor = this.$parent.getFixedColor(userData.nickname);
  
      proitObject.proit = userData.proit == null ? userData.nickname.substring(0, 1) : this.$image_url+userData.proit;
      proitObject.nickname = userData.nickname;
      proitObject.point = userData.point;
      proitObject.is_show_proit = userData.proit == null ? false : true;
      proitObject.isVisible = false;
      return proitObject;
    },
    methods: {
        logout(){
          sessionStorage.clear();
          location.reload();
        },
        showNickname(){
            this.$parent.$refs.AppUsername.showWithPass();
        },
        showWithPass(){
            this.$parent.$refs.AppWithPass.showWithPass();
        },
        showUserPass(){
            this.$parent.$refs.AppUserPass.showUserPass();
        },
        share(){
            this.$parent.$refs.AppShare.showShare();
        },
      showUser() {
        this.isVisible = true;
      },
      hideUser() {
        this.isVisible = false;
      },
      uploadImage() {
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async (event) => {
          const file = event.target.files[0];
          const formData = new FormData();
          formData.append('image', file);
  
          try {
            const response = await fetch(this.$server_url+'/api/v1/uploadIMG', {
              method: 'POST',
              body: formData,
            });
  
            if (response.ok) {
              const imageUrl = await response.text();
              this.proit = this.$image_url+imageUrl;
              this.$parent.proit = this.$image_url+imageUrl;
              this.$parent.is_show_proit = true;
              this.is_show_proit = true;
              this.updateUser(imageUrl);
              this.$closeLoading(hide);
              this.$parent.$refs.AppLoading.hideLoading();

            } else {
              this.$closeLoading(hide);
              this.$parent.$refs.AppLoading.hideLoading();

              console.error('Failed to upload image');
            }
            
          } catch (error) {
            this.$closeLoading(hide);
            this.$parent.$refs.AppLoading.hideLoading();
          }
        };
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();

        input.click();
      },
      async updateUser(imageUrl){
        const response = await axios.post(this.$server_url+'/user/updateUserProit', {
            proit: imageUrl,
            userID: JSON.parse(sessionStorage.getItem('user')).id,
        });
        sessionStorage.setItem('user', response.data.data);
      },
      formatNumber(value) {
        if (!value) return '0';
  
  if (value >= 1_000_000_000) {
    // 十亿及以上，显示 B
    return (value / 1_000_000_000).toFixed(2) + 'B';
  } else if (value >= 1_000_000) {
    // 百万及以上，显示 M
    return (value / 1_000_000).toFixed(2) + 'M';
  } else if (value >= 1_000) {
    // 千及以上，显示 K
    return (value / 1_000).toFixed(2) + 'K';
  }
  
  // 小于 1000，返回原值
  return value.toString();
        },
    },
  };
  </script>
  

<style scoped >
.login_inputs p {
    color: rgb(118, 128, 149) !important;
}
.share_input {
    display: flex;
    box-sizing: border-box;
    border: 1px solid rgb(177, 189, 219);
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    width: 100%;
    height: 43px;
    padding: 5px 0px;
    padding-left: 5px;
    justify-content: space-between;
    align-items: center;
}
.alert_des {
    margin-bottom: 5%;
}
el-avatar {
  display: flex;
    border-radius: 50%;
    width: 82px;
    height: 82px;
    border: 2px solid white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
}
p{
  margin-bottom: 0;
}
</style>
