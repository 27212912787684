<template>
    <div v-if="isVisible" class="mask" style="z-index:110;" >
    </div>
    <div v-if="isVisible" class="app_alert" id="alert_alert">
        <p class="alert_title">{{ $t('alert_title') }}</p>
        <div class="alert_des">
            <p>{{ alertMsg }}</p>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      isVisible: false,
      alertMsg:'',
    };
  },
  methods: {
    showAlert(msg) {
        this.alertMsg = msg;
      this.isVisible = true;
    },
    hideAlert() {
      this.isVisible = false;
    },

  },
};
</script>
<style>
#alert_alert{
  z-index: 200;
}
</style>