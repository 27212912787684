<template>
    <div v-if="isVisible" class="mask">
    </div>
    <div v-if="isVisible" class="app_alert">
        <p class="alert_title">{{ $t('search_room') }}</p>
        <div class="alert_des">
            <div class="login_inputs">
                <div class="login_input">
                    <svg class="left_icon" :class="{ active: activeInput === 'room_number' }" width="10.000000" height="11.503784" viewBox="0 0 10 11.5038" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <desc>
                            Created with Pixso.
                        </desc>
                        <defs/>
                        <path id="矢量 467" d="M6.11 0.38L9.44 3C9.62 3.15 9.76 3.32 9.85 3.51C9.95 3.7 10 3.92 10 4.13L10 8.5C10 8.9 9.82 9.28 9.51 9.56C9.19 9.84 8.77 10 8.33 10L1.66 10C1.22 10 0.8 9.84 0.48 9.56C0.17 9.28 0 8.9 0 8.5L0 4.13C-0.01 3.92 0.04 3.71 0.14 3.51C0.23 3.32 0.37 3.15 0.55 3L3.88 0.38C4.19 0.13 4.59 0 5 0C5.41 0 5.8 0.13 6.11 0.38ZM8.72 8.85C8.83 8.76 8.88 8.63 8.88 8.5L8.88 4.13C8.88 4.06 8.87 3.99 8.83 3.92C8.8 3.86 8.75 3.8 8.7 3.75L5.36 1.13C5.26 1.05 5.13 1.01 5 1.01C4.86 1.01 4.73 1.05 4.63 1.13L1.3 3.75C1.24 3.8 1.19 3.86 1.16 3.92C1.12 3.99 1.11 4.06 1.11 4.13L1.11 8.5C1.11 8.63 1.16 8.76 1.27 8.85C1.37 8.94 1.51 9 1.66 9L8.33 9C8.48 9 8.62 8.94 8.72 8.85ZM5 8L4.99 8C4.71 8 4.49 7.77 4.49 7.5L4.49 6.6C4.49 6.54 4.45 6.5 4.39 6.5L3.5 6.5C3.22 6.5 3 6.28 3 6L3 5.99C3 5.71 3.22 5.49 3.5 5.49L4.39 5.49C4.45 5.49 4.49 5.44 4.49 5.39L4.49 4.5C4.49 4.22 4.71 4 4.99 4L5 4C5.28 4 5.5 4.22 5.5 4.5L5.5 5.39C5.5 5.44 5.54 5.49 5.6 5.49L6.5 5.49C6.77 5.49 7 5.71 7 5.99L7 6C7 6.28 6.77 6.5 6.5 6.5L5.6 6.5C5.54 6.5 5.5 6.54 5.5 6.6L5.5 7.5C5.5 7.77 5.28 8 5 8Z" :fill="activeInput === 'room_number' ? 'rgb(77, 136, 249)' : '#8995B4'" fill-opacity="0.400000" fill-rule="evenodd"/>
                    </svg>
                        <input type="text" v-model="room_number" :placeholder="$t('input_search_room')" @click="changeInput('room_number')">
                </div>
            </div>
        </div>
        <div class="alert_buttons" >
            <button class="alert_cancle_but" type="button" @click="hideSearchRoom" >{{ $t('cancel') }}</button>
            <button class="alert_ok_but" type="button" @click="goRoomAction">{{ $t('ok') }}</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      isVisible: false,
      activeInput: '',
      room_number: "",
    };
  },
  methods: {
    showSearchRoom() {
        this.isVisible = true;
    },
    async goRoomAction(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        const response = await axios.post(this.$server_url+'/api/v1/get_room', {
            room_number: this.room_number,
        });
        if(response.data.code == 0){
            sessionStorage.setItem('room', response.data.room);
            this.isVisible = false;
            this.$parent.$refs.appSelRoom.isVisible = false;
            this.$parent.$refs.appCountRoom.isVisible = true;
            this.$parent.$refs.appCountRoom.initWebSocket(response.data.room.id);
        }else{
            this.$alertMessage('error', this.$t('none_room_error'));

            // this.$parent.$refs.alertComponent.showAlert(this.$t('none_room_error'));
        }
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();
    },
    hideSearchRoom() {
        this.isVisible = false;
    },
    changeInput(inputName) {
        this.activeInput = inputName;
    },
  },
};
</script>
<style>
.login_inputs p{
    color: rgb(118, 128, 149) !important;
}
</style>