import { createI18n } from 'vue-i18n';
import en from './en.json';
import kr from './kr.json';
import cn from './cn.json';

const messages = {
  en,
  kr,
  cn,
};

// 获取系统语言并返回应用的默认语言
export const localLanguage = () => {
  // 获取用户选择的语言或浏览器语言
  const storedLanguage = sessionStorage.getItem("IMI18n");
  const browserLanguage = navigator.language.slice(0, 2); // 只取前两个字符，如 "en"、"zh"

  if (storedLanguage) {
    return storedLanguage; // 如果 sessionStorage 中有用户选择的语言，优先使用
  }

  switch (browserLanguage) {
    case 'kr': // 韩文
    sessionStorage.setItem("IMI18n",'kr');
      return 'kr';
    case 'en': // 英文
    sessionStorage.setItem("IMI18n",'en');
      return 'en';
    case 'zh': // 中文
    sessionStorage.setItem("IMI18n",'cn');
      return 'cn'; // 中文语言代码在此用 'cn'，可根据你的需求调整
    default:
      sessionStorage.setItem("IMI18n",'en');
      return 'en'; // 默认返回英文
  }
};

// 创建 i18n 实例
const i18n = createI18n({
  locale: localLanguage(), // 设置默认语言
  fallbackLocale: 'en', // 设置回退语言
  messages, // 定义语言包
});

export default i18n;
