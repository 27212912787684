<template>
    <div v-if="isVisible" class="mask" style="z-index:102;" >
    </div>
    <div v-if="isVisible" class="app_alert" style="z-index:103;">
        <p class="alert_title">{{ $t('set') }}</p>
        <img class="close_but" src="../assets/image/close.png" alt="" @click="hideShare">
        <div class="alert_des">
            <div class="sound" @click="soundAction" >
                <span>{{ $t('sound') }}</span>
                <img v-if="sound" src="../assets/image/on_but.png" alt="">
                <img v-if="!sound" src="../assets/image/off_but.png" alt="">
            </div>
            <div class="sound" style="margin-top: 14px;" @click="soundEAction" >
                <span>{{ $t('sound_effects') }}</span>
                <img v-if="sound_e" src="../assets/image/on_but.png" alt="">
                <img v-if="!sound_e" src="../assets/image/off_but.png" alt="">
            </div>
            <div>
              <p class="lang" >{{ $t('language') }}</p>
              <select @change="changeLanguage" v-model="selectedLanguage">
                <option value="en">EN-English</option>
                <option value="kr">KO-한국</option>
                <option value="cn">CN-中文</option>
              </select>
            </div>
            <!-- <div>
              <p class="lang">{{ $t('help') }}</p>
            </div> -->
            <div style="border-top: 0.0625rem dashed rgb(177, 189, 219);margin-top: 20px;display: flex;align-items: center;" >
              <img class="small_icon_top" @click="copy" src="../assets/image/email.png" alt="">
              <p class="set_input" >{{ email }}</p>
              <img class="small_icon" @click="copy" src="../assets/image/copy.png" alt="">
            </div>
            <div style="display: flex;align-items: center;" >
              <img class="small_icon_top" @click="copy_tele" src="../assets/image/tele.png" alt="">
              <a class="set_input" href="{{ tele }}" >{{ tele }}</a>
              <img class="small_icon" @click="copy" src="../assets/image/copy.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import axios from 'axios';

export default {
  data() {
    return {
      isVisible: false,
      sound: localStorage.getItem('is_muted') === 'true' ? false : true,
      sound_e: localStorage.getItem('is_muted_e') === 'true' ? false : true,
      selectedLanguage: this.$i18n.locale, // 初始语言
      tele:'',
      email:'',
    };
  },

  methods: {
    copy_tele() {
      navigator.clipboard.writeText(this.tele);
      this.$alertMessage('success', this.$t('copy_success'));
    },
    copy() {
      navigator.clipboard.writeText(this.email);
      this.$alertMessage('success', this.$t('copy_success'));
      
    },
    changeLanguage(event) {
      const newLang = event.target.value;
      this.$i18n.locale = newLang; // 动态设置语言
      sessionStorage.setItem("IMI18n", newLang); // 保存语言设置到 sessionStorage
    },
    soundAction() {
      this.sound = !this.sound;
      if (!this.sound) {
        this.$parent.$refs.sound.pauseAudio('bac1');
        this.$parent.$refs.sound.pauseAudio('bac2');
        localStorage.setItem('is_muted', 'true');
      } else {
        this.$parent.$refs.sound.playBgSound();
        localStorage.setItem('is_muted', 'false');
      }
      
      var is_muted = localStorage.getItem('is_muted');
      
      // console.log("____________~~~~is_muted_e："+is_muted);
    },
    soundEAction() {
      this.sound_e = !this.sound_e;
      if (!this.sound_e) {
        localStorage.setItem('is_muted_e', 'true');
      } else {
        localStorage.setItem('is_muted_e', 'false');
      }
      
      // var is_muted_e = localStorage.getItem('is_muted_e');
      
      // console.log("____________~~~~is_muted_e："+is_muted_e);
    },
    showShare() {
      this.getHelp();
    },
    hideShare() {
      this.isVisible = false;
    },
    async getHelp(){
      const hide = this.$loading();

      const response = await axios.post(this.$server_url+'/api/v1/get_help', {
        
      });
      this.email = response.data.help.email;
      this.tele = response.data.help.tele_url;
      this.$closeLoading(hide);
      this.isVisible = true;
    },
  },
};
</script>

<style scoped>
.login_inputs p {
    color: rgb(118, 128, 149) !important;
}
.share_input {
    display: flex;
    box-sizing: border-box;
    border: 1px solid rgb(177, 189, 219);
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    width: 100%;
    height: 43px;
    padding: 5px 0px;
    padding-left: 5px;
    justify-content: space-between;
    align-items: center;
}
.alert_des {
    margin-bottom: 5%;
}
select{
  width: 100%;
  margin-top: 7px;
  padding: 5px 0;
}
.lang{
  margin: 0;
  font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: rgb(118, 128, 149);
    margin-top: 20px;
    
}
.set_input{
  width: 100%;
  padding: 5px 0;
  border: none;
}
.help_div{
  display: flex;
  background: white;
  border: 1px solid rgb(177, 189, 219);
  align-items: center;
}
.small_icon{
  width: 20px;
  height: 20px;
}
.small_icon_top{
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
a{
  color: rgb(45, 54, 72);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0;
    text-align: left;
    word-break: break-all;
}
</style>
