<template>
  <div>
    <!-- 音频元素 -->
    <audio ref="bac1" :src="bac1Src" loop ></audio>
    <audio ref="bac2" :src="bac2Src" loop ></audio>
    <audio ref="batting" :src="battingSrc"></audio>
    <audio ref="boom" :src="boomSrc"></audio>
    <audio ref="breakEgg" :src="breakEggSrc"></audio>
    <audio ref="clockCount" :src="clockCountSrc"></audio>
  </div>
</template>

<script>

import bac1Src from '@/assets/sound/bac1.mp3';
import bac2Src from '@/assets/sound/bac2.mp3';
import battingSrc from '@/assets/sound/batting.mp3';
import boomSrc from '@/assets/sound/boom.mp3';
import breakEggSrc from '@/assets/sound/break_egg.mp3';
import clockCountSrc from '@/assets/sound/clock_count.mp3';

export default {
  data() {
    return {
      bgName:"bac1",
      bac1Src,
      bac2Src,
      battingSrc,
      boomSrc,
      breakEggSrc,
      clockCountSrc,
    };
  },
  methods: {
    playBgSound(){
      this.$refs[this.bgName].play();
    },
    playAudio(refName) {
      this.bgName = refName;
      var is_muted = localStorage.getItem('is_muted');
      
      if(is_muted == 'false'){
        this.$refs[refName].play();
      }
    },
    playEffects(refName) {
      var is_muted_e = localStorage.getItem('is_muted_e');
      if(is_muted_e == 'false' ){
        this.$refs[refName].play();
      }
    },
    pauseAudio(refName) {
      // if(is_muted == 'false'){
      this.$refs[refName].pause();
      // }
    },
  }
};
</script>
