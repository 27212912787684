<template>
    <div v-if="isVisible" class="mask" style="z-index:105;" >
    </div>
    <div v-if="isVisible" class="app_alert" style="z-index:106;">
        <p class="alert_title">{{ $t('with_pass') }}</p>
        <div class="alert_des">
            <div class="login_inputs">
                <div v-if="isOldVisible" class="login_input">
                    <p>{{ $t('old_password') }}</p>
                    <div>
                        <svg class="left_icon" :class="{ active: activeInput === 'password' }" width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>Created with Pixso.</desc>
                            <defs/>
                            <g style="mix-blend-mode:normal">
                                <g style="mix-blend-mode:normal">
                                    <path id="icon/lock-off" d="M6 5L10 5C9.99951 3.89587 9.10425 3.00098 8 3.00098C6.89575 3.00098 6.00049 3.89587 6 5ZM11 5L12.2307 5C12.6556 5 13 5.51167 13 6.14285L13 11.8571C13 12.4883 12.6556 13 12.2307 13L3.76929 13C3.34436 13 3 12.4883 3 11.8571L3 6.14285C3 5.51167 3.34436 5 3.76929 5L5 5C5.00049 3.3436 6.34351 2.00098 8 2.00098C9.65649 2.00098 10.9995 3.3436 11 5ZM12 12L12 6L4 6L4 12L12 12ZM7.51172 8L8.51172 8L8.51172 11L7.51172 11L7.51172 8Z" clip-rule="evenodd" :fill="activeInput === 'password' ? 'rgb(77, 136, 249)' : '#8995B4'" fill-opacity="1.000000" fill-rule="evenodd"/>
                                </g>
                            </g>
                        </svg>
                        <input :type="passwordFieldType" v-model="password" :placeholder="$t('input_password_placeholder')" @click="changeInput('password')">
                        <svg v-if="isPasswordIconChanged" @click="show_pass()" class="right_icon" width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>Created with Pixso.</desc>
                            <defs/>
                            <g style="mix-blend-mode:normal">
                                <g style="mix-blend-mode:normal"/>
                                <g style="mix-blend-mode:normal">
                                    <path id="browse-off" d="M12.8535 12.6454L12.1465 13.3525L10.7732 11.9792C9.9043 12.318 8.96875 12.4988 8.00488 12.4988C5.10156 12.4988 2.46143 10.8688 1.11694 8.22876L1 7.99878L1.11694 7.7688C1.72754 6.57458 2.60522 5.58435 3.65332 4.85925L2.44263 3.64868L3.14966 2.94153L12.8535 12.6454ZM12.5894 10.9683L11.8809 10.2599C12.696 9.6803 13.3894 8.91431 13.8992 7.99878C12.6814 5.82886 10.4502 4.49878 8.00488 4.49878C7.4314 4.49878 6.87012 4.57141 6.3313 4.71033L5.53125 3.91016C6.31519 3.64148 7.14868 3.49878 8.00488 3.49878C10.8984 3.49878 13.5386 5.13879 14.8831 7.7688L15 7.99878L14.8831 8.22876C14.3201 9.33423 13.53 10.2625 12.5894 10.9683ZM9.86401 8.24292L10.6787 9.05774C10.8083 8.72998 10.8796 8.37268 10.8796 7.99878C10.8796 6.40833 9.59033 5.11902 7.99976 5.11902C7.62598 5.11902 7.26855 5.19019 6.94092 5.31982L7.75562 6.13464C7.83545 6.12427 7.91699 6.11902 7.99976 6.11902C9.03784 6.11902 9.87964 6.96081 9.87964 7.99878C9.87964 8.08154 9.87439 8.16309 9.86401 8.24292ZM7.46997 7.8482L5.3208 5.69904C4.57275 6.32227 4.00732 7.1283 3.65674 7.99878C4.87451 10.1687 7.10571 11.4988 9.55078 11.4988C10.2075 11.4988 10.8364 11.3879 11.4214 11.1809L10.4236 10.1831C10.1042 10.2767 9.68457 10.3494 9.13464 10.3494C8.09662 10.3494 7.25482 9.50757 7.25482 8.46954C7.25482 7.91962 7.32751 7.49995 7.42114 7.1806L7.46997 7.8482ZM6.52588 6.90412L5.92285 6.30115C5.78223 6.62964 5.70654 6.98828 5.70654 7.36536C5.70654 8.95557 6.99585 10.2449 8.58606 10.2449C8.96313 10.2449 9.32178 10.1692 9.65027 10.0286L9.0473 9.42554C8.86438 9.47864 8.61279 9.49878 8.30908 9.49878C7.14355 9.49878 6.50195 8.85718 6.50195 7.69165C6.50195 7.38794 6.52209 7.13635 6.5752 6.95343Z" clip-rule="evenodd" fill="rgb(77, 136, 249)" fill-opacity="1.000000" fill-rule="evenodd"/>
                                </g>
                            </g>
                        </svg>
                        <svg v-if="!isPasswordIconChanged" @click="show_pass()" class="right_icon" width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>
                                    Created with Pixso.
                            </desc>
                            <defs/>
                            <g style="mix-blend-mode:normal">
                                <g style="mix-blend-mode:normal"/>
                                <g style="mix-blend-mode:normal">
                                    <path id="browse" d="M8.00488 12.5C10.8984 12.5 13.5386 10.87 14.8831 8.22998L15 8L14.8831 7.77002C13.5386 5.14001 10.8984 3.5 8.00488 3.5C5.10156 3.5 2.46143 5.14001 1.11694 7.77002L1 8L1.11694 8.22998C2.46143 10.87 5.10156 12.5 8.00488 12.5ZM8.00488 4.5C5.5498 4.5 3.3186 5.83002 2.1106 8C3.3186 10.17 5.5498 11.5 8.00488 11.5C10.4502 11.5 12.6909 10.17 13.8992 8C12.6814 5.83002 10.4502 4.5 8.00488 4.5ZM5.11987 8C5.11987 9.59052 6.40918 10.8799 7.99976 10.8799C9.59033 10.8799 10.8796 9.59052 10.8796 8C10.8796 6.40955 9.59033 5.12018 7.99976 5.12018C6.40918 5.12018 5.11987 6.40955 5.11987 8ZM6.11987 8C6.11987 9.03821 6.96143 9.87988 7.99976 9.87988C9.03784 9.87988 9.87964 9.03821 9.87964 8C9.87964 6.96179 9.03784 6.12018 7.99976 6.12018C6.96143 6.12018 6.11987 6.96179 6.11987 8Z" clip-rule="evenodd" fill="#000000" fill-opacity="1.000000" fill-rule="evenodd"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="login_input">
                    <p>{{ $t('new_password') }}</p>
                    <div>
                        <svg class="left_icon" :class="{ active: activeInput === 'repassword' }" width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>Created with Pixso.</desc>
                            <defs/>
                            <g style="mix-blend-mode:normal">
                                <g style="mix-blend-mode:normal">
                                    <path id="icon/lock-off" d="M6 5L10 5C9.99951 3.89587 9.10425 3.00098 8 3.00098C6.89575 3.00098 6.00049 3.89587 6 5ZM11 5L12.2307 5C12.6556 5 13 5.51167 13 6.14285L13 11.8571C13 12.4883 12.6556 13 12.2307 13L3.76929 13C3.34436 13 3 12.4883 3 11.8571L3 6.14285C3 5.51167 3.34436 5 3.76929 5L5 5C5.00049 3.3436 6.34351 2.00098 8 2.00098C9.65649 2.00098 10.9995 3.3436 11 5ZM12 12L12 6L4 6L4 12L12 12ZM7.51172 8L8.51172 8L8.51172 11L7.51172 11L7.51172 8Z" clip-rule="evenodd" :fill="activeInput === 'repassword' ? 'rgb(77, 136, 249)' : '#8995B4'" fill-opacity="1.000000" fill-rule="evenodd"/>
                                </g>
                            </g>
                        </svg>
                        <input :type="repasswordFieldType" v-model="repassword" :placeholder="$t('input_password_placeholder')" @click="changeInput('repassword')">
                        <svg v-if="isrePasswordIconChanged" @click="show_repass()" class="right_icon" width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>Created with Pixso.</desc>
                            <defs/>
                            <g style="mix-blend-mode:normal">
                                <g style="mix-blend-mode:normal"/>
                                <g style="mix-blend-mode:normal">
                                    <path id="browse-off" d="M12.8535 12.6454L12.1465 13.3525L10.7732 11.9792C9.9043 12.318 8.96875 12.4988 8.00488 12.4988C5.10156 12.4988 2.46143 10.8688 1.11694 8.22876L1 7.99878L1.11694 7.7688C1.72754 6.57458 2.60522 5.58435 3.65332 4.85925L2.44263 3.64868L3.14966 2.94153L12.8535 12.6454ZM12.5894 10.9683L11.8809 10.2599C12.696 9.6803 13.3894 8.91431 13.8992 7.99878C12.6814 5.82886 10.4502 4.49878 8.00488 4.49878C7.4314 4.49878 6.87012 4.57141 6.3313 4.71033L5.53125 3.91016C6.31519 3.64148 7.14868 3.49878 8.00488 3.49878C10.8984 3.49878 13.5386 5.13879 14.8831 7.7688L15 7.99878L14.8831 8.22876C14.3201 9.33423 13.53 10.2625 12.5894 10.9683ZM9.86401 8.24292L10.6787 9.05774C10.8083 8.72998 10.8796 8.37268 10.8796 7.99878C10.8796 6.40833 9.59033 5.11902 7.99976 5.11902C7.62598 5.11902 7.26855 5.19019 6.94092 5.31982L7.75562 6.13464C7.83545 6.12427 7.91699 6.11902 7.99976 6.11902C9.03784 6.11902 9.87964 6.96081 9.87964 7.99878C9.87964 8.08154 9.87439 8.16309 9.86401 8.24292ZM7.46997 7.8482L5.3208 5.69904C4.57275 6.32227 4.00732 7.1283 3.65674 7.99878C4.87451 10.1687 7.10571 11.4988 9.55078 11.4988C10.2075 11.4988 10.8364 11.3879 11.4214 11.1809L10.4236 10.1831C10.1042 10.2767 9.68457 10.3494 9.13464 10.3494C8.09662 10.3494 7.25482 9.50757 7.25482 8.46954C7.25482 7.91962 7.32751 7.49995 7.42114 7.1806L7.46997 7.8482ZM6.52588 6.90412L5.92285 6.30115C5.78223 6.62964 5.70654 6.98828 5.70654 7.36536C5.70654 8.95557 6.99585 10.2449 8.58606 10.2449C8.96313 10.2449 9.32178 10.1692 9.65027 10.0286L9.0473 9.42554C8.86438 9.47864 8.61279 9.49878 8.30908 9.49878C7.14355 9.49878 6.50195 8.85718 6.50195 7.69165C6.50195 7.38794 6.52209 7.13635 6.5752 6.95343Z" clip-rule="evenodd" fill="rgb(77, 136, 249)" fill-opacity="1.000000" fill-rule="evenodd"/>
                                </g>
                            </g>
                        </svg>
                        <svg v-if="!isrePasswordIconChanged" @click="show_repass()" class="right_icon" width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>
                                    Created with Pixso.
                            </desc>
                            <defs/>
                            <g style="mix-blend-mode:normal">
                                <g style="mix-blend-mode:normal"/>
                                <g style="mix-blend-mode:normal">
                                    <path id="browse" d="M8.00488 12.5C10.8984 12.5 13.5386 10.87 14.8831 8.22998L15 8L14.8831 7.77002C13.5386 5.14001 10.8984 3.5 8.00488 3.5C5.10156 3.5 2.46143 5.14001 1.11694 7.77002L1 8L1.11694 8.22998C2.46143 10.87 5.10156 12.5 8.00488 12.5ZM8.00488 4.5C5.5498 4.5 3.3186 5.83002 2.1106 8C3.3186 10.17 5.5498 11.5 8.00488 11.5C10.4502 11.5 12.6909 10.17 13.8992 8C12.6814 5.83002 10.4502 4.5 8.00488 4.5ZM5.11987 8C5.11987 9.59052 6.40918 10.8799 7.99976 10.8799C9.59033 10.8799 10.8796 9.59052 10.8796 8C10.8796 6.40955 9.59033 5.12018 7.99976 5.12018C6.40918 5.12018 5.11987 6.40955 5.11987 8ZM6.11987 8C6.11987 9.03821 6.96143 9.87988 7.99976 9.87988C9.03784 9.87988 9.87964 9.03821 9.87964 8C9.87964 6.96179 9.03784 6.12018 7.99976 6.12018C6.96143 6.12018 6.11987 6.96179 6.11987 8Z" clip-rule="evenodd" fill="#000000" fill-opacity="1.000000" fill-rule="evenodd"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="login_input">
                    <p>{{ $t('check_new_password') }}</p>
                    <div>
                        <svg class="left_icon" :class="{ active: activeInput === 'check_password' }" width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>Created with Pixso.</desc>
                            <defs/>
                            <g style="mix-blend-mode:normal">
                                <g style="mix-blend-mode:normal">
                                    <path id="icon/lock-off" d="M6 5L10 5C9.99951 3.89587 9.10425 3.00098 8 3.00098C6.89575 3.00098 6.00049 3.89587 6 5ZM11 5L12.2307 5C12.6556 5 13 5.51167 13 6.14285L13 11.8571C13 12.4883 12.6556 13 12.2307 13L3.76929 13C3.34436 13 3 12.4883 3 11.8571L3 6.14285C3 5.51167 3.34436 5 3.76929 5L5 5C5.00049 3.3436 6.34351 2.00098 8 2.00098C9.65649 2.00098 10.9995 3.3436 11 5ZM12 12L12 6L4 6L4 12L12 12ZM7.51172 8L8.51172 8L8.51172 11L7.51172 11L7.51172 8Z" clip-rule="evenodd" :fill="activeInput === 'repassword' ? 'rgb(77, 136, 249)' : '#8995B4'" fill-opacity="1.000000" fill-rule="evenodd"/>
                                </g>
                            </g>
                        </svg>
                        <input :type="checkpasswordFieldType" v-model="check_password" :placeholder="$t('input_check_password_placeholder')" @click="changeInput('check_password')">
                        <svg v-if="ischeckpasswordIconChanged" @click="show_check_password()" class="right_icon" width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>Created with Pixso.</desc>
                            <defs/>
                            <g style="mix-blend-mode:normal">
                                <g style="mix-blend-mode:normal"/>
                                <g style="mix-blend-mode:normal">
                                    <path id="browse-off" d="M12.8535 12.6454L12.1465 13.3525L10.7732 11.9792C9.9043 12.318 8.96875 12.4988 8.00488 12.4988C5.10156 12.4988 2.46143 10.8688 1.11694 8.22876L1 7.99878L1.11694 7.7688C1.72754 6.57458 2.60522 5.58435 3.65332 4.85925L2.44263 3.64868L3.14966 2.94153L12.8535 12.6454ZM12.5894 10.9683L11.8809 10.2599C12.696 9.6803 13.3894 8.91431 13.8992 7.99878C12.6814 5.82886 10.4502 4.49878 8.00488 4.49878C7.4314 4.49878 6.87012 4.57141 6.3313 4.71033L5.53125 3.91016C6.31519 3.64148 7.14868 3.49878 8.00488 3.49878C10.8984 3.49878 13.5386 5.13879 14.8831 7.7688L15 7.99878L14.8831 8.22876C14.3201 9.33423 13.53 10.2625 12.5894 10.9683ZM9.86401 8.24292L10.6787 9.05774C10.8083 8.72998 10.8796 8.37268 10.8796 7.99878C10.8796 6.40833 9.59033 5.11902 7.99976 5.11902C7.62598 5.11902 7.26855 5.19019 6.94092 5.31982L7.75562 6.13464C7.83545 6.12427 7.91699 6.11902 7.99976 6.11902C9.03784 6.11902 9.87964 6.96081 9.87964 7.99878C9.87964 8.08154 9.87439 8.16309 9.86401 8.24292ZM7.46997 7.8482L5.3208 5.69904C4.57275 6.32227 4.00732 7.1283 3.65674 7.99878C4.87451 10.1687 7.10571 11.4988 9.55078 11.4988C10.2075 11.4988 10.8364 11.3879 11.4214 11.1809L10.4236 10.1831C10.1042 10.2767 9.68457 10.3494 9.13464 10.3494C8.09662 10.3494 7.25482 9.50757 7.25482 8.46954C7.25482 7.91962 7.32751 7.49995 7.42114 7.1806L7.46997 7.8482ZM6.52588 6.90412L5.92285 6.30115C5.78223 6.62964 5.70654 6.98828 5.70654 7.36536C5.70654 8.95557 6.99585 10.2449 8.58606 10.2449C8.96313 10.2449 9.32178 10.1692 9.65027 10.0286L9.0473 9.42554C8.86438 9.47864 8.61279 9.49878 8.30908 9.49878C7.14355 9.49878 6.50195 8.85718 6.50195 7.69165C6.50195 7.38794 6.52209 7.13635 6.5752 6.95343Z" clip-rule="evenodd" fill="rgb(77, 136, 249)" fill-opacity="1.000000" fill-rule="evenodd"/>
                                </g>
                            </g>
                        </svg>
                        <svg v-if="!ischeckpasswordIconChanged" @click="show_check_password()" class="right_icon" width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>
                                    Created with Pixso.
                            </desc>
                            <defs/>
                            <g style="mix-blend-mode:normal">
                                <g style="mix-blend-mode:normal"/>
                                <g style="mix-blend-mode:normal">
                                    <path id="browse" d="M8.00488 12.5C10.8984 12.5 13.5386 10.87 14.8831 8.22998L15 8L14.8831 7.77002C13.5386 5.14001 10.8984 3.5 8.00488 3.5C5.10156 3.5 2.46143 5.14001 1.11694 7.77002L1 8L1.11694 8.22998C2.46143 10.87 5.10156 12.5 8.00488 12.5ZM8.00488 4.5C5.5498 4.5 3.3186 5.83002 2.1106 8C3.3186 10.17 5.5498 11.5 8.00488 11.5C10.4502 11.5 12.6909 10.17 13.8992 8C12.6814 5.83002 10.4502 4.5 8.00488 4.5ZM5.11987 8C5.11987 9.59052 6.40918 10.8799 7.99976 10.8799C9.59033 10.8799 10.8796 9.59052 10.8796 8C10.8796 6.40955 9.59033 5.12018 7.99976 5.12018C6.40918 5.12018 5.11987 6.40955 5.11987 8ZM6.11987 8C6.11987 9.03821 6.96143 9.87988 7.99976 9.87988C9.03784 9.87988 9.87964 9.03821 9.87964 8C9.87964 6.96179 9.03784 6.12018 7.99976 6.12018C6.96143 6.12018 6.11987 6.96179 6.11987 8Z" clip-rule="evenodd" fill="#000000" fill-opacity="1.000000" fill-rule="evenodd"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert_buttons" >
            <button class="alert_cancle_but" type="button" @click="hideWithPass" >{{ $t('cancel') }}</button>
            <button class="alert_ok_but" type="button" @click="editAction">{{ $t('ok') }}</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      isVisible: false,
      isOldVisible: JSON.parse(sessionStorage.getItem('user')).outcoin_pass == null ? false : true,
      activeInput: '',
      room_number: "",
      password:'',
      repassword:'',
      check_password:'',
      isrePasswordIconChanged: false,
      isPasswordIconChanged: false,
      ischeckpasswordIconChanged: false,
    };
  },
  computed: {
        passwordFieldType() {
            return this.isPasswordIconChanged ? 'text' : 'password';
        },
        repasswordFieldType() {
            return this.isrePasswordIconChanged ? 'text' : 'password';
        },
        checkpasswordFieldType(){
            return this.ischeckpasswordIconChanged ? 'text' : 'password';
        }
    },
  methods: {
    show_pass(){
        this.isPasswordIconChanged = ! this.isPasswordIconChanged;
    },
    show_repass(){
        this.isrePasswordIconChanged = ! this.isrePasswordIconChanged;
    },
    show_check_password(){
        this.ischeckpasswordIconChanged = ! this.ischeckpasswordIconChanged;
    },
    showWithPass() {
        this.isVisible = true;
    },
    async editAction(){
        if(this.check_password != this.repassword){
            this.$alertMessage('error', this.$t('password_repassword_error'));

            // this.$parent.$refs.alertComponent.showAlert( this.$t('password_repassword_error') );
            return false;
        }
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        const response = await axios.post(this.$server_url+'/user/with_password', {
            id: JSON.parse(sessionStorage.getItem('user')).id,
            password: this.password,
            repassword: this.repassword,
        });
        if(response.data.code == 0){
            sessionStorage.setItem('user', response.data.data);
            this.password='';
            this.repassword='';
            this.check_password='';
            this.$closeLoading(hide);
            this.$alertMessage('success', this.$t('edit_success'));
            this.hideWithPass();
        }else if(response.data.code == 1){
            this.$closeLoading(hide);
            this.$alertMessage('error', this.$t('old_password_error'));

            // this.$parent.$refs.alertComponent.showAlert(this.$t('none_room_error'));
        }else if(response.data.code == 2){
            this.$closeLoading(hide);
            this.$alertMessage('error', this.$t('old_new_password_error'));
        }
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();
        this.isVisible = false;
    },
    hideWithPass() {
        this.isVisible = false;
        this.$parent.$refs.AppOutcoin.hideOutcoin();
    },
    changeInput(inputName) {
        this.activeInput = inputName;
    },
  },
};
</script>
<style>
.login_inputs p{
    color: rgb(118, 128, 149) !important;
}
</style>