<template>
    <div v-if="isVisible" class="mask" style="z-index:102;" >
    </div>
    <div v-if="isVisible" class="app_alert" style="z-index:103;">
        <p class="alert_title">{{ $t('share') }}</p>
        <img class="close_but" src="../assets/image/close.png" alt="" @click="hideShare">
        <div class="alert_des">
            <div class="login_inputs">
              <p>{{ $t('share_code') }}</p>
              <div class="share_input" style="margin-top: 3%;">
                  <span>{{ invitation_code }}</span>
                  <img src="../assets/image/share_but.png" alt="" @click="copy">
              </div>
                <p>{{ $t('share_url') }}</p>
                <div class="share_input">
                    <p>{{ invitation_code_url }}</p>
                    <img src="../assets/image/share_but.png" alt="" @click="copyUrl">
                </div>
                <div class="qrcode" style="margin-top: 3%;height: auto;">
                    <img :src="qrCodeDataUrl" alt="QR Code">
                    <div class="qrcode_text" >
                      <p>{{ $t('sharing_qr_title') }}</p>
                      <p>{{ $t('sharing_qr_des') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
  data() {
    return {
      isVisible: false,
      activeInput: '',
      invitation_code_url: this.$local_host + "/regist?code=" + JSON.parse(sessionStorage.getItem('user')).come_code,
      invitation_code: JSON.parse(sessionStorage.getItem('user')).come_code,
      qrCodeDataUrl: '',
    };
  },
  mounted() {
    this.generateQRCode();
  },
  methods: {
    async generateQRCode() {
      try {
        this.qrCodeDataUrl = await QRCode.toDataURL(this.invitation_code_url);
      } catch (error) {
        console.error('Failed to generate QR code:', error);
      }
    },
    copy() {
      // document.execCommand(this.invitation_code);
      navigator.clipboard.writeText(this.invitation_code);
      this.$alertMessage('success', this.$t('copy_success'));
    },
    copyUrl() {
      // document.execCommand(this.invitation_code_url);
      navigator.clipboard.writeText(this.invitation_code_url);
      this.$alertMessage('success', this.$t('copy_success'));
    },
    showShare() {
      this.isVisible = true;
    },
    hideShare() {
      this.isVisible = false;
    },
    changeInput(inputName) {
      this.activeInput = inputName;
    },
  },
};
</script>

<style>
.login_inputs p {
    color: rgb(118, 128, 149) !important;
}
.share_input {
    display: flex;
    box-sizing: border-box;
    border: 1px solid rgb(177, 189, 219);
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    width: 100%;
    height: 43px;
    padding: 5px 0px;
    padding-left: 5px;
    justify-content: space-between;
    align-items: center;
}
.alert_des {
    margin-bottom: 5%;
}
</style>
