// src/utils/dynamicFontSize.js
export function setFontSize() {
    const html = document.documentElement;
    const width = html.clientWidth;
    const height = html.clientHeight;
    
    // 这里可以根据屏幕尺寸调整 font-size
    const baseFontSize = 12; // 以16px为基础的font-size
    const ratio = width / 320; // 假设 320px 为设计稿宽度

    html.style.fontSize = `${baseFontSize * ratio}px`;
}