<template>
    <div v-if="isVisible" class="mask" style="z-index:102;" >
    </div>
    <div v-if="isVisible" class="app_alert" style="z-index:103;">
        <p class="alert_title">{{ $t('recharge') }}</p>
        <div class="alert_des">
            <img class="coinlogo" src="../assets/image/usdtlogo.png" alt="">
            <p class="network" >{{ network }}</p>
            <div class="login_inputs">
                <p>{{ $t('from') }}</p>
                <div class="login_input">
                    <div class="address">
                        <textarea v-model="userAddress" ></textarea>
                        <img v-if="is_ok_but" class="small_icon" src="../assets/image/ok_icon.png" alt="">
                        <img v-if="is_unok_but" class="small_icon" src="../assets/image/unok_icon.png" alt="">
                    </div>
                    <!-- <span class="address_but" @click="setUserAddress" >{{ $t('ok') }}</span> -->
                </div>
                <p class="login_input_text" style="color: rgb(136, 136, 138) !important;" >{{ $t('account_check') }}</p>
                <p v-if="isQrcode" >{{ $t('to') }}</p>
                <div v-if="isQrcode" class="login_input">
                    <div class="address">
                        <textarea v-model="address" readonly ></textarea>
                        <img class="small_icon" @click="copy" src="../assets/image/copy.png" alt="">
                    </div>
                </div>
                <div v-if="isQrcode" class="qrcode" style="margin-top: 3%;height: auto;">
                    <img :src="qrCodeDataUrl" alt="QR Code">
                    <div class="qrcode_text" >
                        <p style="font-size: 10px;" >{{ $t('incoin_text') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert_buttons" >
            <button v-if="isCheckAccount" class="alert_cancle_but" type="button" @click="hideIncoin" >{{ $t('cancle') }}</button>
            <button v-if="isCheckAccount" class="alert_ok_but" type="button" @click="checkAccount">{{ $t('ok') }}</button>
            <button v-if="isCancle" class="alert_ok_but" type="button" @click="hideIncoin">{{ $t('ok') }}</button>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode';
import axios from 'axios';

export default {
  data() {
    return {
      isVisible: false,
      activeInput: '',
      address: "",
      userAddress: "",
      qrCodeDataUrl: '',
      network: "",
      isQrcode: false,
      is_ok_but: false,
      is_unok_but: false,
      isCheckAccount: true,
      isCancle: false,
    };
  },
  mounted() {
    
    
  },
  methods: {
    async checkAccount(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        try {
            const response = await axios.post(this.$server_url+'/user/setUserAddress', {
                user_id: JSON.parse(sessionStorage.getItem('user')).id,
                address: this.userAddress,
            });
            if(response.data.code == 0){
                this.is_unok_but = false;
                this.is_ok_but = true;
                this.isQrcode = true;
                this.isCheckAccount = false;
                this.isCancle = true;
                this.generateQRCode();
            }else{
                this.is_unok_but = true;
                this.is_ok_but = false;
            }
        } catch (error) {
            this.$closeLoading(hide);
            this.$parent.$refs.AppLoading.hideLoading();
            // alert(error);
            // this.$alertMessage('error', this.$t('response_error'));
            // this.$parent.$refs.alertComponent.showAlert(this.$t('response_error'));
        }
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();
    },
    // async setUserAddress(){
    //     try {
    //         const response = await axios.post(this.$server_url+'/user/setUserAddress', {
    //             user_id: JSON.parse(sessionStorage.getItem('user')).id,
    //             address: this.userAddress,
    //         });
    //         if(response.data.code == 0){
    //             this.isQrcode = true;
    //             this.generateQRCode();
    //         }
    //     } catch (error) {
    //         alert(error);
    //         this.$alertMessage('error', this.$t('response_error'));
    //         // this.$parent.$refs.alertComponent.showAlert(this.$t('response_error'));
    //     }
    // },
    async getAddress(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        try {
            const response = await axios.post(this.$server_url+'/user/getAddress', {
                user_id: JSON.parse(sessionStorage.getItem('user')).id,
            });
            
            if (response.data.code == 0) {
                
                this.network = response.data.network.name;
                this.address = response.data.systemWallet.coinAddress;
                this.userAddress = response.data.userCoinInfo.coinAddress;
                if(this.userAddress != null && this.userAddress != ''){
                    this.is_ok_but = true;
                }
                this.generateQRCode();
                
            }else if(response.data.code == 2){
                this.$alertMessage('error', this.$t('address_is_used'));
            }
        } catch (error) {
            this.$parent.$refs.AppLoading.hideLoading();
            this.$closeLoading(hide);
            // this.$alertMessage('error', this.$t('response_error'));

            // this.$parent.$refs.alertComponent.showAlert(this.$t('response_error'));
        }
        this.$parent.$refs.AppLoading.hideLoading();
        this.$closeLoading(hide);
    },
    async generateQRCode() {
      try {
        this.qrCodeDataUrl = await QRCode.toDataURL(this.address);
      } catch (error) {
        console.error('Failed to generate QR code:', error);
      }
    },
    copy() {
      navigator.clipboard.writeText(this.address);
    //   document.execCommand(this.address);
      this.$alertMessage('success', this.$t('copy_success'));
      
    //   onError(this.$t('copy_success'));
    },
    showRecharge() {
        this.getAddress();
        
      this.isVisible = true;
    },
    hideIncoin() {
      this.isVisible = false;
    },
    changeInput(inputName) {
      this.activeInput = inputName;
    },
  },
};
</script>

<style scoped >
.login_input_text{
    line-height: 12px;
    font-size: 10px;
    font-weight: 400;
}
.address_but{
    background: linear-gradient(rgb(252, 217, 51), rgb(254, 174, 22));
    padding: 4px 4px;
    border-radius: 5px;
    width: 55px;
    text-align: center;
    margin-left: 5px;
}
.coinlogo{
    width: 46px;
}
.login_inputs p {
    color: rgb(118, 128, 149) !important;
}
.share_input {
    display: flex;
    box-sizing: border-box;
    border: 1px solid rgb(177, 189, 219);
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    width: 100%;
    height: 43px;
    padding: 5px 0px;
    padding-left: 5px;
    justify-content: space-between;
    align-items: center;
}
.alert_des {
    margin-bottom: 5%;
}
.address{
    box-sizing: border-box;
    border: 1px solid rgb(177, 189, 219);
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 12px;
    width: 100%;
    padding: 0px 3px;
    display: flex;
    align-items: center;
}
textarea{
    box-sizing: border-box;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 12px;
    width: 100%;
    border: none;
    padding: 10px 4px;
}
.network{
    font-size: 12px;
    text-align: center;;
}
.login_input{
    display:flex;
    align-items: center;
    flex-direction: row;
}
p{
    margin-bottom: 0;
}
.small_icon{
    width: 20px;
}
.alert_title{
    margin: 10px 0;
}
</style>
