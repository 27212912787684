<template>
  <Sound ref="sound" />
  <router-view />
</template>

<script>
import { defineComponent } from 'vue';
import './assets/css/common.css'
import router from './router';  // 导入路由配置
import Sound from './views/SoundManager.vue';  // 导入声音组件

export default defineComponent({
  components: {
    Sound,  // 声音组件注册到局部组件中
  },
  // 注入路由配置
  created() {
    this.$router = router;
  }
});
</script>
<style>
p{
  margin: 0;
}
</style>
