<template>
    
    <div v-if="isVisible" class="room" >
        <img @click="go_room(0)" src="../../assets/image/add10_egg.png" alt="">
        <img @click="go_room(1)" src="../../assets/image/addin_egg.png" alt="">
    </div>
        
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            isVisible: true,
        }
    },
    methods: {
        async go_room(mode){
            const hide = this.$loading();
            this.$parent.$refs.AppLoading.showLoading();
            var user_id = JSON.parse(sessionStorage.getItem('user')).id;
            const response = await axios.post(this.$server_url+'/room/getRoom', {
                mode: mode,
                user_id: user_id,
            });
            if (response.data.code == 0) {
                sessionStorage.setItem('room', response.data.data);
                this.isVisible = false;
                this.$parent.$refs.appCountRoom.isVisible = true;
                this.$parent.$refs.appCountRoom.initWebSocket(JSON.parse(response.data.data).id);
            }
            this.$closeLoading(hide);
            this.$parent.$refs.AppLoading.hideLoading();
        },
    }
}
</script>

<style scoped>


</style>
