<!-- AppLoading.vue -->
<template>
  <div  v-if="isVisible" class="loadingClass">
    <a-spin tip="Loading..."/>
  </div>>
</template>
<script>
  export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    showLoading() {        
      this.isVisible = true;
    },
    hideLoading() {
      this.isVisible = false;
    },
  },
}
</script>
<style scoped>
.loadingClass {
  width: 100%;
    height: 100%;
    background: black;
    position: absolute;
    opacity: 0.5;
    z-index: 1002;
}
</style>