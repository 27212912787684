<template>
    <div v-if="isVisible" class="count_room" >
        
        <div class="show_image_area" >
          <div class="totalPoint" >
            <p>{{ formatNumber(totalPoint) }}</p>
          </div>
          <div class="point_boom" >
            <div>
              <img src="../../assets/image/coin.png" alt="">
              <span class="round_point" >{{ formatNumber(roundPoint) }}</span>
            </div>
          </div>
          <span v-if="is_boom_show" class="boom_img" >{{ formatNumber(roundBoom) }}</span>

        </div>
        <div class="count_users" id="count_usersID" v-html="user_html" >
            
        </div>
        <div class="alert_left_user" v-html="left_user" >

        </div>
        <!-- <div class="alert_user_bac" >
            <img src="../../assets/image/alert_user_bac_blue.png" alt="">
            
            <div class="alert_user_proit" >
              <el-avatar class="all_empty_img" style="color:black;background:red">
          g
          </el-avatar>
              <p class="count_empty_name orange" style="margin-left: 0;" >username</p>
            </div>
            <span class="alert_user_point" >+100</span>
          </div> -->
        <div class="alert_right_user" v-html="right_user" >
          
        </div>
        <div class="egg_area" >
            <img v-if="baoji" class="baoji_img" @click="open_egg()" src="../../assets/image/baoji.gif" alt="">
            <img v-if="countOne" @click="buy_action()" class="count_img" src="../../assets/image/count_1.png" alt="">
            <img v-if="countTwo" @click="buy_action()" class="count_img" src="../../assets/image/count_2.png" alt="">
            <img v-if="countThree" @click="buy_action()" class="count_img" src="../../assets/image/count_3.png" alt="">
            <img v-if="buyButton" @click="buy_action()" class="egg_break" src="../../assets/image/egg_bet.png" alt="">
            <img v-if="buyButtonGray" class="egg_wait" src="../../assets/image/egg_wait.png" alt="">
            <img v-if="egg" @click="open_egg()" src="../../assets/image/egg_normal.png" alt="">
            <img v-if="egg" @click="open_egg()" class="hanma" src="../../assets/image/hanma.gif" alt="">
            <div v-if="addResult" >
              <p class="break_egg_point" >+{{ point }}</p>
                <img src="../../assets/image/01.png" alt="">
            </div>
            <div v-if="delResult" >
              <p v-if="delResultP" class="break_egg_point" >-{{ point }}</p>
                <img v-if="boom_1" id="02_1" src="../../assets/image/02_1.png" alt="">
                <img v-if="boom_2" id="02_2" src="../../assets/image/02_2.png" alt="">
                <img v-if="boom_3" id="02_3" src="../../assets/image/02_3.png" alt="">
            </div>
        </div>
        <div v-if="is_clock_show" class="clock" >
            <img src="../../assets/image/clock.png" alt="">
            <span>{{ time }}</span>
        </div>
    </div>
</template>
  
<script>
import { nextTick } from 'vue';
import grayPoint from '@/assets/image/small_coin_gray.png'; // 使用 @ 符号作为 src 的别名
import yellowPoint from '@/assets/image/small_coin_yellow.png'; // 使用 @ 符号作为 src 的别名
import userBacBlue from '@/assets/image/alert_user_bac_blue.png'; // 使用 @ 符号作为 src 的别名
import userBacBlack from '@/assets/image/alert_user_bac_black.png'; // 使用 @ 符号作为 src 的别名
import userBacOrange from '@/assets/image/alert_user_bac_orange.png'; // 使用 @ 符号作为 src 的别名


    export default {

    data() {
        return {
              delResultP:false,
              boom_1:true,
              boom_2:false,
              boom_3:false,
              baoji:false,
              userCount: 0,//JSON.parse(sessionStorage.getItem('room')).user_count, // 假设你有10个 count_user
              useSeat: 0,//已经使用座位
              user_html:"",
              left_user:"",
              right_user:"",
              isVisible: false,
              buyButton: true,
              buyButtonGray: false,
              addResult: false,
              delResult: false,
              egg: false,
              room_name: "",
              websock: null, // 建立的连接
              lockReconnect: false, // 是否真正建立连接
              timeout: 20 * 1000, // 20秒一次心跳
              timeoutObj: null, // 心跳心跳倒计时
              serverTimeoutObj: null, // 心跳倒计时
              timeoutnum: null, // 断开重连倒计时
              manuallyClosed: false, // 手动断开连接的标志
              countOne: false,
              countTwo: false,
              countThree: false,
              roomID: 0,
              roundPoint:0,
              roundBoom:0,
              totalPoint:0,
              time:0,
              point:0,
              overTime:null,
              is_clock_show:false,
              is_boom_show:false,
              grayPoint,
              yellowPoint,
              userBacBlue,
              userBacBlack,
              userBacOrange,
              stausBatting:false,
          }
    },
    
    unmounted() {
      this.user_html = "";
      this.closeWebSocket();
      this.timerClose();
      if(this.timeoutnum)clearTimeout(this.timeoutnum);
      if(this.timeoutObj)clearTimeout(this.timeoutObj);
      this.initData();
      console.log("===================unmounted===================");
    },
    methods: {
        initData(){
          this.delResultP = false;
          this.boom_1=true;
          this.boom_2=false;
          this.boom_3=false;
          this.baoji=false;
          this.userCount= 0;
          this.useSeat= 0;//已经使用座位
          this.user_html="";
          this.left_user="",
          this.right_user="",
          this.isVisible= false;
          this.buyButton= true;
          this.buyButtonGray= false;
          this.addResult= false;
          this.delResult= false;
          this.egg= false;
          this.room_name= "";
          // this.websock= null; // 建立的连接
          this.lockReconnect= false; // 是否真正建立连接
          this.timeout= 20 * 1000; // 20秒一次心跳
          this.timeoutObj= null; // 心跳心跳倒计时
          this.serverTimeoutObj= null; // 心跳倒计时
          this.timeoutnum= null; // 断开重连倒计时
          this.manuallyClosed= false; // 手动断开连接的标志
          this.countOne= false;
          this.countTwo= false;
          this.countThree= false;
          this.roomID= 0;
          this.roundPoint=0;
          this.roundBoom=0;
          // this.totalPoint=0;
          this.time=0;
          this.point=0;
          this.overTime=null;
          this.is_clock_show=false;
          this.is_boom_show=false;
          this.stausBatting= false;
        },
        open_egg(){
          
          var data = {
                        code: "openEgg",              // 添加 "code" 属性
                        userID: JSON.parse(sessionStorage.getItem('user')).id,  // 获取并添加 "userID" 属性
                        roomID: this.roomID
                    };
          this.websocketsend(JSON.stringify(data));
        },
        buy_action(){
          if(this.stausBatting)return;
            if(this.useSeat <= this.userCount){
              this.$parent.$refs.sound.playEffects('batting');
                if(JSON.parse(sessionStorage.getItem('user')).point>10){
                    var data = {
                        code: "buy",              // 添加 "code" 属性
                        useSeat: this.useSeat,    // 添加 "useSeat" 属性
                        userID: JSON.parse(sessionStorage.getItem('user')).id,  // 获取并添加 "userID" 属性
                        roomID: this.roomID
                    };
                    
                    this.websocketsend(JSON.stringify(data));
                }else{
                  // this.$alertMessage('warning', this.$t('sockentError.point_over_error'));
                  this.$parent.$refs.AppIncoinAlert.showAlert(this.$t('sockentError.point_over_error'));
                }
            }else{
              this.$alertMessage('warning', this.$t('sockentError.user_count_over_error'));
                // this.$parent.$refs.alertComponent.showAlert( this.$t('sockentError.user_count_over_error') );
            }
        },
    // 建立连接，初始化websocket
    initWebSocket(roomID) {
      this.$parent.is_show_share = true;
        this.roomID = roomID;
        this.manuallyClosed = false; // 重置手动断开标志
        const wsuri = this.$socket_url+roomID+"/"+JSON.parse(sessionStorage.getItem('user')).id;
        this.websock = new WebSocket(wsuri);
        this.websock.onopen = this.websocketonopen;
        this.websock.onerror = this.websocketonerror;
        this.websock.onmessage = this.websocketonmessage;
        this.websock.onclose = this.websocketclose;
    },
    reconnect() {
      if (this.lockReconnect || this.manuallyClosed) {
        return;
      }
      
      this.useSeat = 0;
      this.userCount = 0;
      this.lockReconnect = true;
      this.timeoutnum && clearTimeout(this.timeoutnum);
      this.timeoutnum = setTimeout(() => {
        console.log("WebSocket连接发生错误：reconnect");
        this.initWebSocket(this.roomID);
        this.lockReconnect = false;
      }, 5000);
    },
    reset() {
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      this.start();
    },
    start() {
      this.timeoutObj && clearTimeout(this.timeoutObj);
      this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
      // console.log(this.serverTimeoutObj);
      // this.timeoutObj = setTimeout(() => {
      //   if (this.websock.readyState === WebSocket.OPEN) {
      //   //   var data = {
      //   //                 code: "ping",              // 添加 "code" 属性
      //   //                 userID: JSON.parse(sessionStorage.getItem('user')).id,  // 获取并添加 "userID" 属性
      //   //             };
      //   // this.websocketsend(JSON.stringify(data));
      //   } else {
      //     this.reconnect();
      //   }
      // }, this.timeout);
    },
    websocketonopen() {
      this.start();
    },
    websocketonerror() {
      // console.log("WebSocket连接发生错误");
      this.reconnect();
    },
    websocketclose(event) {
      // console.log("连接已关闭", event);
      if (!this.manuallyClosed) {
        this.reconnect();
      }
    },
    closeWebSocket() {
      this.is_clock_show = false;
      this.timerClose();
      if(this.timeoutnum)clearTimeout(this.timeoutnum);
      if(this.timeoutObj)clearTimeout(this.timeoutObj);
      this.initData();
      this.$parent.is_show_share = false;
      if (this.websock) {
        this.manuallyClosed = true;
        this.websock.close();
        // console.log("关闭websock");
      }
      // console.log("关闭房间");
    },
    websocketonmessage(event) {
        
    //   if(event.data != null && event.data != ""){
    //       this.useSeat = JSON.parse(event.data).useSeat;
    //   }
    //   this.reset();
        if(this.manuallyClosed)return;
        var data = JSON.parse(event.data);
        // console.log("===================websocketonmessage:");
        console.log(data.code);
        console.log(event.data);
        // console.log("----------------------------------------------------------------------------------------------");
        switch(data.code){
            case "error":
            this.$alertMessage('warning', this.$t("sockentError."+data.msg));
                // this.$parent.$refs.alertComponent.showAlert( this.$t("sockentError."+data.msg) );
                break;
            case "maintenance":
              this.$parent.$refs.AppNoButtonAlert.showAlert(this.$t("maintenance"));
              break;
            case "info"://更改自己的数据
            // console.log("info:"+data.user.user);
            
              sessionStorage.setItem('user', JSON.stringify(data.user.user));
              this.$parent.point = data.user.user.point;
              break;
            case "batting"://押注
                this.battingAction(data);
                break;
            case "battingOverTimeStart"://全部押注完成倒计时开始
                this.time = 3;
                this.battingOverTimeStart(data);
                break;
            case "breakEggTimeStart"://开始砸蛋，并且倒计时
                this.time = data.breakEggStartTime;
                this.$parent.$refs.sound.pauseAudio('bac1');
                this.$parent.$refs.sound.playAudio('bac2');
                if(this.stausBatting == true){
                    this.stausEgg(3);
                }else{
                  this.stausEgg(2);
                }
                if(data.isBunusRoom.isBunusRoom){
                  this.baoji = true;
                }
                this.roundPoint = data.totalPrice+data.isBunusRoom.totalBunusPoint;
                this.refreshPoint();
                this.breakEggTimeStart(data);
                break;
            case "init":{//初始化房间
                this.totalPoint = data.bunusPoint;
                let totalSum = 0;
                
                
                var pointAllNum = data.room.total_price + data.totalBunusPoint;

                if(data.generatedAllList != null){
                  totalSum = data.generatedAllList.reduce((acc, num) => acc + num, 0);
                }
                this.roundPoint = pointAllNum - totalSum;

                this.initAction(data);
                break;
            }
            case "comeToBossUser"://更新房主
                sessionStorage.setItem("roomBossUserID",data.roomBossUserID);
                break;
            case "openEgg"://砸蛋
                this.openEgg(data);
                break;
            case "roundOverInitData":
              this.roundOverInitData();
              break;
            case "nextRound"://下一个回合
                this.nextRound(data);
                break;
            case "bunusTotalPoint":
                this.totalPoint = data.bunusPoint;
                break;
            default:
                console.log("nonecode:"+event.data);
                break;

        }

    },
    refreshPoint(){
       // 获取所有带有 class="my-class" 的 p 标签
       const paragraphs = document.querySelectorAll('.count_empty_point');
                // console.log("_____paragraphs_______"+JSON.stringify(paragraphs));
                // 遍历每一个 p 标签并替换它的内容
                paragraphs.forEach((p, index) => {
                  p.textContent = `0`;
                  // console.log("_____p_______"+JSON.stringify(p));
                });
    },
    roundOverInitData(){
      this.$parent.$refs.sound.playAudio('bac1');
      this.$parent.$refs.sound.pauseAudio('bac2');

      if(this.boomTimeout_1)clearTimeout(this.boomTimeout_1);
      if(this.boomTimeout_2)clearTimeout(this.boomTimeout_2);
      this.boomTimeout_1 = null;
      this.boomTimeout_2 = null;
      // this.boom_1 = false;
      // this.boom_2 = false;
      // this.boom_3 = false;
      // this.delResultP = false;


      
      this.left_user="",
      this.right_user="",
      this.left_user_count=0;
      this.right_user_count0;
      this.useSeat = 0;
      this.buyButton = true;
      this.buyButtonGray = false;
      this.addResult = false;
      this.delResult = false;
      this.egg = false;
      this.roundPoint=0;
      this.roundBoom=0;
      // this.totalPoint=0;
      this.time=0;
      this.point=0;
      this.boomTimeout_1
      

      this.stausBatting= false;
      this.user_html = "";
    },
    nextRound(data){
      this.initAction(data.data);
    },
    overRound(){
      this.time = 0;
      var senddatas = {
        code: "overRound",              // 添加 "code" 属性
        roomID: this.roomID,
      };
      this.websocketsend(JSON.stringify(senddatas));
    },
    openEgg(data){
      // console.log("*****************openEgg:"+JSON.stringify(data));
      // console.log("round_point++++++++"+this.roundPoint);
      if(data.userFlow!=null){
        this.baoji = false;
        // if(data.userFlow.user_id == JSON.parse(sessionStorage.getItem('user')).id){
          this.$parent.$refs.sound.playEffects('breakEgg');
        // }
        this.useSeat -- ;
        // if(this.useSeat<1){
        //   this.overRound();
        //   return false;
        // }
        // console.log("*****************openEgg:"+sessionStorage.getItem("room"));
        var room = JSON.parse(sessionStorage.getItem("room"));
        var point = data.userFlow.total_price;
        
        this.roundPoint = this.roundPoint - point;
        var result = data.userFlow.result;
        if(room.room_type == 0){
          //如果不存在这个元素就return
          if (!document.getElementById("re_" + data.userFlow.user_id)) {
            return;
          }
          if(result == 0){

            var html = `<img src="${this.yellowPoint}" /><span>`+this.formatNumber(point)+`</span>`;
            document.getElementById("re_"+data.userFlow.user_id).innerHTML = html;
            document.getElementById("re_"+data.userFlow.user_id).style.display = 'flex';
            
          }else{
            
            var html = `<img src="${this.grayPoint}" /><span>-`+this.formatNumber(point)+`</span>`;
            document.getElementById("re_"+data.userFlow.user_id).innerHTML = html;
            document.getElementById("re_"+data.userFlow.user_id).style.display = 'flex';
          }
          // document.getElementById("point_"+data.userFlow.user_id).innerHTML = this.formatNumber(this.point);
          document.getElementById("point_"+data.userFlow.user_id).textContent = this.formatNumber(point);
          // console.log("document______"+JSON.stringify(document.getElementById("point_"+data.userFlow.user_id)));
          // console.log("document__user_id____"+data.userFlow.user_id);
          // console.log("document__user_id____"+"point_"+data.userFlow.user_id);
          // console.log("document__ㅔㅐㅑㅜ____"+"point_"+data.userFlow.user_id);

        }
        var proit = '';
        if(data.user.proit == null || data.user.proit == ''){
          proit = `<el-avatar class="all_empty_img" style="color:black;background:`+this.$parent.getFixedColor(data.user.nickname)+`">
          `+data.user.nickname.substring(0,1)+`
          </el-avatar>`;
        }else{
          proit = `<img class="" src="`+data.user.proit+`" />`;
        }
        if(room.room_type == 1){
          var html1 = `<div class="alert_user_bac" >
            <img src="`+this.userBacBlue+`" alt="">
            
            <div class="alert_user_proit" >
              `+proit+`
              <p class="count_empty_name orange" style="margin-left: 0;" >`+data.user.nickname+`</p>
            </div>
            <span class="alert_user_point" >+`+this.formatNumber(data.userFlow.total_price)+`</span>
          </div>`;
          this.right_user = html1;
          
        }
        if(JSON.parse(sessionStorage.getItem('user')).id == data.userFlow.user_id){
          // data.userFlow.total_price;
          // data.userFlow.result;
          this.egg = false;
          this.point = point;
          // console.log("*****************come_here**************");
          if(result == 0){
            // this.addResult = true;
            
            this.stausEgg(4);
            // this.stausEgg(5);
            if(room.room_type == 0){
              var html = `<img src="${this.yellowPoint}" /><span style="color:#FF7403;" >`+this.formatNumber(this.point)+`</span>`;
              document.getElementById("re_"+data.userFlow.user_id).innerHTML = html;
            }
            
          }else{
            // this.delResult = true;
            //人数房
            if(room.room_type == 0){
              this.$parent.$refs.sound.playEffects('boom');
              var html = `<img src="${this.grayPoint}" /><span>-`+this.point+`</span>`;
              document.getElementById("re_"+data.userFlow.user_id).innerHTML = html;
            }else if(room.room_type == 1){
              var html1 = `<div class="alert_user_bac" >
                <img src="`+this.userBacBlack+`" alt="">
                <div class="alert_user_proit" >
                  <img class="" src="`+data.user.proit+`" />
                  <p class="count_empty_name orange" style="margin-left: 0;" >`+data.user.nickname+`</p>
                </div>
                <span class="alert_user_point" >`+this.formatNumber(data.userFlow.total_price)+`</span>
              </div>`;
              this.right_user += html1;
            }
            
            //无论什么房间都执行炸弹动画
            // this.changeBoomImg();
            this.stausEgg(5);
          }
          if(this.useSeat<1 && room.room_type ==0){
              this.overRound();
            return false;
          }
          // if(this.time == 0){
          //   var senddata = {
          //         code: "nextRound",              // 添加 "code" 属性
          //         roomID: this.roomID,
          //       };
          //       this.websocketsend(JSON.stringify(senddata));
          // }
        }
      }
    },
    stausEgg(value){
      switch(value){
        case 1://押注
          this.buyButton = true;
          this.buyButtonGray = false;
          this.egg = false;
          this.addResult = false;

          if(this.boomTimeout_1)clearTimeout(this.boomTimeout_1);
          if(this.boomTimeout_2)clearTimeout(this.boomTimeout_2);
          this.boomTimeout_1 = null;
          this.boomTimeout_2 = null;
          this.boom_1 = false;
          this.boom_2 = false;
          this.boom_3 = false;
          this.delResultP = false;
          break;
        case 2://等待
          this.buyButton = false;
          this.buyButtonGray = true;
          this.egg = false;
          this.addResult = false;
          break;
        case 3://砸蛋
          this.buyButton = false;
          this.buyButtonGray = false;
          this.egg = true;
          this.addResult = false;
          if(this.boomTimeout_1)clearTimeout(this.boomTimeout_1);
          if(this.boomTimeout_2)clearTimeout(this.boomTimeout_2);
          this.boomTimeout_1 = null;
          this.boomTimeout_2 = null;
          this.boom_1 = false;
          this.boom_2 = false;
          this.boom_3 = false;
          this.delResultP = false;
          break;
        case 4://成功
          this.buyButton = false;
          this.buyButtonGray = false;
          this.egg = false;
          this.addResult = true;
          break;
        case 5://炸弹动画
          this.buyButton = false;
          this.buyButtonGray = false;
          this.egg = false;
          this.addResult = false;
          this.delResult = true;
          this.changeBoomImg();
          // this.delResultP = true;
          break;
      }
    },
    changeBoomImg(){
      this.boom_1 = true;
      this.boom_2 = false;
      this.boom_3 = false;
      this.delResultP = false;
      this.boomTimeout_1 = setTimeout(() => {
          this.boom_1 = false;
          this.boom_2 = true;
          this.boom_3 = false;
        }, 500);

      this.boomTimeout_2 = setTimeout(() => {
        this.boom_1 = false;
        this.boom_2 = false;
        this.boom_3 = true;
        this.delResultP = true;
      }, 2000);
    },
    breakEggTimeStart(data){
        // this.roundPoint = data.totalPrice+data.isBunusRoom.totalBunusPoint;
       

        if(data.boomCount > 0){
          this.is_boom_show = true;
        }
        this.roundBoom = data.boomCount;
        // this.totalPoint = data.bunusPoint;
        // 每秒发送一次请求
        // console.log("____________~~~~this.time"+this.time);
        if(this.time > 0){
          
          this.is_clock_show = true;
          this.time --;
          this.timerClose()
          this.overTime = setTimeout(() => {
            this.breakEggTimeStart(data);
            },  1000);
        }else{
          this.baoji = false;
          this.time = 0;
          this.timerClose();
          var userID = JSON.parse(sessionStorage.getItem('user')).id;
          var roomBossUserID = JSON.parse(sessionStorage.getItem('roomBossUserID'));
          if(userID == roomBossUserID){
              this.overRound();
          }
          setTimeout(() => {
            // this.stausEgg(3);
            var userID = JSON.parse(sessionStorage.getItem('user')).id;
            var roomBossUserID = JSON.parse(sessionStorage.getItem('roomBossUserID'));
            // overRound();
            
            // console.log("____________~~~~this.userID"+userID);
            // console.log("____________~~~~this.roomBossUserID"+roomBossUserID);
            if(userID == roomBossUserID){
              this.user_html = "";
              var data = {
                code: "nextRound",              // 添加 "code" 属性
                roomID: this.roomID,
                userID: userID
              };
              this.websocketsend(JSON.stringify(data));
            }
          }, 1500);
        }
    },
    userBattingStaus(useUsers,userID){
      var boon =false
      for (let value of useUsers) {
          if(value.userid == userID){
            boon = true;
            break;
          }
        }
        return boon;
    },
    battingOverTimeStart(data){
        // 每秒发送一次请求
        console.log("________________________________________battingOverTimeStart:"+this.time);
        if(this.time > 0){
          
          //根据秒数显示图片
          if(this.time == 3){
            this.$parent.$refs.sound.playEffects('clockCount');
            this.countOne = false;
            this.countTwo = false;
            this.countThree = true;
          }
          if(this.time == 2){
            this.countOne = false;
            this.countTwo = true;
            this.countThree = false;
          }
          if(this.time == 1){
            this.countOne = true;
            this.countTwo = false;
            this.countThree = false;
          }
          this.time --;
          this.timerClose()
          this.overTime = setTimeout(() => {
            this.battingOverTimeStart(data);
            },  1000);
        }else{
          this.countOne = false;
          this.countTwo = false;
          this.countThree = false;
          var userID = JSON.parse(sessionStorage.getItem('user')).id;
          var roomBossUserID = JSON.parse(sessionStorage.getItem('roomBossUserID'));
          if(userID == roomBossUserID){
            console.log("************I am Boos***************");
            var sendData = {
              code: "battingOverTimeout",              // 添加 "code" 属性
              roomID: this.roomID
            };
            this.websocketsend(JSON.stringify(sendData));
          }
          // console.log("________________________________________batting:"+JSON.stringify(data));
          if(data.useUsers != null){
            //更改已下注人的蛋的状态
            // for (let value of data.useUsers) {
            //   if(value.userid == userID){
            //     this.stausBatting = true;
            //     this.stausEgg(3);
            //   }
            // }
          }else{
            var sendDatas = {
              code: "battingOverTimeout",              // 添加 "code" 属性
              roomID: this.roomID
            };
            this.websocketsend(JSON.stringify(sendDatas));
          }
        }
    },
    initAction(data){
      console.log("initAction~~~~~~~~~~~~~~~~~~"+JSON.stringify(data));
      this.user_html = "";
      this.time = 0;
      sessionStorage.setItem("roomBossUserID",data.roomBossUserID);
      var list = data.useUsers;
      if(list != null){
        this.useSeat = list.length;
      }else{
        this.useSeat = 0;
      }
      this.userCount = data.room.user_count;
      var userID = JSON.parse(sessionStorage.getItem('user')).id

      if(data.useUsers != null){
        for (let value of data.useUsers) {
          if(value.userid == userID){
            this.stausBatting = true;
          }
        }
      }
      //人数房
      if(data.room.room_type == 0){
        this.creat_user_html(list);
        // if(list){
        //   list.forEach(user => {
        //     const element = document.getElementById(`re_`+user.userid);
        //     console.log("+++++element+++++++++"+element);
        //     if (element) { // 确保元素存在
        //       let html = '';
        //       if(user.result == 0){
        //         html = `<img src="${this.yellowPoint}" /><span>${this.formatNumber(user.openPoint)}</span>`;
        //       } else {
        //         html = `<img src="${this.grayPoint}" /><span>-${this.formatNumber(user.openPoint)}</span>`;
        //       }
        //       element.innerHTML = html;
        //       element.style.display = 'flex';
        //       document.getElementById("point_"+user.userid).textContent = this.formatNumber(user.openPoint);
        //     } else {
        //       console.warn(`Element re_${user.userid} not found`);
        //     }
        //   });
        // }


        if(data.room.type == 0){
          if(data.roundFlow){
            this.stausEgg(2);
          }else{
            this.stausEgg(1);
          }
        }else if(data.room.type == 1){
          this.stausEgg(2);
          this.time = data.nowBattingHaveTime;
          this.battingOverTimeStart(data);
        }
        else if(data.room.type == 2){
          if(this.stausBatting){

            if(data.roundFlow.is_open  == 1){
              if(data.roundFlow.result == 0){
                this.stausEgg(4);
              }else{
                this.stausEgg(5);
              }
            }else{
              this.stausEgg(3);
            }
          }
          this.time = data.breakEggStartTime;
          this.breakEggTimeStart(data);
        }
      }else{
        //无限放
        if(data.room.type == 0){
          if(data.roundFlow){
            this.stausEgg(2);
          }else{
            this.stausEgg(1);
          }
          this.time = data.nowBattingHaveTime;
          this.battingOverTimeStart(data);
        }
        else if(data.room.type == 1){
            this.stausEgg(3);
            this.time = data.breakEggStartTime;
            this.breakEggTimeStart(data);
        }else {
          if(data.roundFlow.is_open  == 1){
              if(data.roundFlow.result == 0){
                this.stausEgg(4);
              }else{
                this.stausEgg(5);
              }
            }else{
              this.stausEgg(3);
            }
            this.time = data.breakEggStartTime;
            this.breakEggTimeStart(data);
        }
      }
      
      if(this.time > 0){
        this.is_clock_show = true;
      }else{
        this.is_clock_show = false;
      }
    },
    battingAction(data){
      var room = JSON.parse(sessionStorage.getItem("room"));
      this.roundPoint += data.data.room.price;
      var userID = JSON.parse(sessionStorage.getItem('user')).id
      if(userID == data.data.user.userid){
        this.stausEgg(2);
        this.stausBatting= true;
      }
      if(room.room_type == 1){
        var proit = '';
            if(data.data.user.proit == null || data.data.user.proit == ''){
              proit = `<el-avatar class="all_empty_img" style="color:black;background:`+this.$parent.getFixedColor(data.data.user.nickname)+`">
              `+data.data.user.nickname.substring(0,1)+`
          </el-avatar>`;
            }else{
              proit = `<img class="" src="`+data.data.user.proit+`" />`;
            }
        var html = `<div class="alert_user_bac" >
              <img src="`+this.userBacOrange+`" alt="">
              
              <div class="alert_user_proit" >
                `+proit+`
                <p class="count_empty_name orange" style="margin-left: 0;" >`+data.data.user.nickname+`</p>
              </div>
              <span class="alert_user_point" >+`+this.formatNumber(data.data.room.price)+`</span>
            </div>`;
            this.left_user = html;
      }
      else if(data.data.room.room_type == 0){
        if(document.getElementById("userPorit"+this.useSeat)){
          document.getElementById("userPorit"+this.useSeat).innerHTML = this.creat_html(data.data.user);
          this.useSeat ++;
        }else{
          console.log("userPorit"+this.useSeat);
        }
      }
    },
    websocketsend(msg) {
      console.log("###############################websocketsend:"+msg);
      if (this.websock && this.websock.readyState === WebSocket.OPEN) {
        this.websock.send(msg);
      } else {
        console.error("WebSocket连接尚未建立，无法发送消息。");
      }
    },
    creat_user_html(list){
      
        let html = '';
        const pairs = Math.ceil(this.userCount / 2); // 每个 between 中最多2个 count_user
        for (let i = 0; i < pairs; i++) {
          html += '<div class="between"  id="between">';
            for (let j = 0; j < 2; j++) {
              const userIndex = i * 2 + j;
              if (userIndex < this.userCount) {
                if(list != null && list[userIndex] != null && list.length>userIndex){
                  html += `
                  <div class="count_user" id="`+"userPorit"+userIndex+`" >`
                    +this.creat_html(list[userIndex])+
                    `</div>`;
                   
                  }else{
                    
                    html +=`
                    <div class="count_user" id="`+"userPorit"+userIndex+`" >
                        <p class="count_empty_img">?</p>
                        <p class="count_empty_name">empty</p>
                        <p class="count_empty_point">0.00</p>
                    </div>
                    `;
                  }
                }
            }

            html += '</div>';
        }
        // console.log("html_______________:"+html);
        this.user_html = html;
        // console.log("html_______________user_html:"+this.user_html);
    },
    creat_html(user){
      var html = '';
      var back_class = '';
      var back_class_num = '';
        let html_result = '';
        var result_style = 'display:none;';
        var point = this.formatNumber(user.point);
        
        if(user.openPoint != 0){
          result_style = 'display:flex;';
          point = this.formatNumber(user.openPoint);
          if(user.result == 0){
            html_result = `<img src="${this.yellowPoint}" /><span>${this.formatNumber(user.openPoint)}</span>`;
          } else {
            html_result = `<img src="${this.grayPoint}" /><span>-${this.formatNumber(user.openPoint)}</span>`;
          }
        }
      
      
      // console.log("battingAction-user:"+JSON.stringify(user));
      if(user.nickname == JSON.parse(sessionStorage.getItem('user')).nickname){
        
          this.stausEgg(2);
          back_class = 'orange';
          back_class_num = 'orange_num';
      }else{
          back_class = 'purple';
          back_class_num = 'purple_num';
      }

      if(user.proit == null){
          html = `
          <div style="`+result_style+`" class="result" id="re_`+user.userid+`" >`+html_result+`</div>
          <el-avatar class="count_empty_img" style="color:black;background:`+this.$parent.getFixedColor(user.nickname)+`">
              `+user.nickname.substring(0,1)+`
          </el-avatar>
          <p class="count_empty_name `+back_class+`">`+user.nickname+`</p>
          <p class="count_empty_point `+back_class_num+`" id="point_`+user.userid+`" >`+point+`</p>
          `;
      }else{
          html = `
              <div style="`+result_style+`" class="result" id="re_`+user.userid+`" >`+html_result+`</div>
              <img class="count_empty_img" src="`+this.$image_url+user.proit+`" />
              <p class="count_empty_name `+back_class+`">`+user.nickname+`</p>
              <p class="count_empty_point `+back_class_num+`"  id="point_`+user.userid+`"  >`+point+`</p>
          `;
      }
      console.log("htmlhtmlhtmlhtmlthml:"+html);
      return html;
    },
    timerClose(){
      if(this.overTime)clearTimeout(this.overTime);
      this.overTime = null;
    },
    formatNumber(value) {
      if (!value) return '0.00';  // 返回 0.00

      if (value >= 1_000_000_000) {
        // 十亿及以上，显示 B
        return (value / 1_000_000_000).toFixed(2) + 'B';
      } else if (value >= 1_000_000) {
        // 百万及以上，显示 M
        return (value / 1_000_000).toFixed(2) + 'M';
      } else if (value >= 1_000) {
        // 千及以上，显示 K
        return (value / 1_000).toFixed(2) + 'K';
      }

      // 小于 1000，返回原值，保持两位小数
      return value.toFixed(2);
    }

  }
}
//<img src="${this.grayPoint}" /><span>-5.0</span>
</script>
<style scoped>

.egg_break{
  animation: slideDown 1s ease-out forwards;
}
.count_img{
  animation: growAndFadeAnimation 1s forwards;
}
.hanma{
  position: absolute;
    width: 75px;
    margin-left: -49%;
    margin-top: 20px;
    opacity: 0;
    animation: fadeInAnimation 1s forwards;
}
p{
  margin-bottom: 0 !important;
}
.baoji_img{
  position: absolute;
    z-index: 1;
    margin-top: 2.25rem;
    margin-left: 4.5625rem;
    width: 11.75rem;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes growAndFadeAnimation {
  0% {
      transform: scale(1);
      opacity: 1;
  }
  100% {
      transform: scale(2);
      opacity: 0;
  }
}
@keyframes slideDown {
  0% {
    /* transform: translateY(-100px); 从上方移入 */
    opacity: 0;
  }
  100% {
    /* transform: translateY(0); 到达目标位置 */
    opacity: 1;
  }
}
</style>