import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import SoundManager from './media/SoundManager.vue';
import { DatePicker } from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import { message } from 'ant-design-vue';
import { setFontSize } from '@/utils/dynamicFontSize';

// 设置字体大小
setFontSize();

// 监听窗口尺寸变化以动态调整字体大小
window.addEventListener('resize', setFontSize);

const app = createApp(App)
  .use(router)
  .use(store)
  .use(SoundManager)
  .use(DatePicker)
  .use(i18n);

// 定义全局变量
app.config.globalProperties.$local_host = 'http://t-egg.betplayhub.com';
app.config.globalProperties.$server_url = 'https://t-api.betplayhub.com';
app.config.globalProperties.$image_url = 'https://t-image.betplayhub.com';
app.config.globalProperties.$socket_url = 'wss://t-api.betplayhub.com/ws/';

// app.config.globalProperties.$local_host = 'http://localhost:8081';
// app.config.globalProperties.$server_url = 'http://localhost:9090';
// app.config.globalProperties.$image_url = 'http://localhost:3333';
// app.config.globalProperties.$socket_url = 'ws://localhost:9090/ws/';



console.log("local_host:"+app.config.globalProperties.$local_host);
console.log("server_url:"+app.config.globalProperties.$server_url);
console.log("image_url:"+app.config.globalProperties.$image_url);
// 定义全局函数
app.config.globalProperties.$alertMessage = function (type, msg) {
  message.config({
    duration: 2,
    maxCount: 1,
  });
  if (type === 'success') {
    message.success(msg);
  } else if (type === 'error') {
    message.error(msg);
  } else if (type === 'warning') {
    message.warning(msg);
  }
};

app.config.globalProperties.$loading = function () {
  const hide = message.loading('Loading', 0);
  return hide;
};

app.config.globalProperties.$closeLoading = function (hide) {
  hide();
};

app.mount('#app');
