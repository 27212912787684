<template>
    <div class="none" >
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  }
  </script>
  
  <style scoped>
  /* 添加样式 */
  .none {
    width: 100vw;
    height: 100vh;
    background: black;
  }
  </style>
  