<template>
    <div v-if="isVisible" class="mask">
    </div>
    <div v-if="isVisible" class="app_alert">
        <p class="alert_title">{{ $t('create_room') }}</p>
        <div class="alert_des">
            <div class="login_inputs">
                <p>{{ $t('input_user_count') }}</p>
                <div class="login_input">
                    <select  v-model="user_count">
                        <option value="3">2</option>
                        <option value="4">4</option>
                        <option value="6">6</option>
                        <option value="8">8</option>
                        <option value="10">10</option>
                        <option value="0">无限</option>
                    </select>
                </div>
                <p>{{ $t('input_room_point') }}</p>
                <div class="login_input">
                    <svg class="left_icon" :class="{ active: activeInput === 'room_point' }" width="10.000000" height="11.503784" viewBox="0 0 10 11.5038" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <desc>
                            Created with Pixso.
                        </desc>
                        <defs/>
                        <path id="椭圆 30" d="M5 10C7.75 10 10 7.75 10 5C10 2.24 7.75 0 5 0C2.24 0 0 2.24 0 5C0 7.75 2.24 10 5 10ZM8.05 2.43C8.63 3.12 8.99 4.02 8.99 5C8.99 5.9 8.68 6.81 8.05 7.56C7.47 8.25 6.65 8.76 5.69 8.92C4.8 9.08 3.84 8.94 3 8.45C2.21 8 1.58 7.28 1.25 6.36C0.93 5.51 0.91 4.55 1.25 3.63C1.55 2.78 2.15 2.03 3 1.54C3.78 1.09 4.73 0.89 5.69 1.07C6.58 1.22 7.43 1.68 8.05 2.43Z" :fill="activeInput === 'room_point' ? 'rgb(77, 136, 249)' : '#D0D5E1'" fill-opacity="1.000000" fill-rule="evenodd"/>
                        <path id="矢量 30" d="M4.63 8L5.57 8L5.57 7.26C6.52 7.1 7 7.22 7 5.9C7 4.58 4.59 4.61 4.59 3.99C4.59 3.68 4.81 3.57 5.24 3.57C5.6 3.57 5.89 3.68 6.22 3.91L6.93 3.28C6.58 2.99 6.15 2.77 5.57 2.71L5.57 2L4.63 2L4.63 2.72C3.75 2.86 3.23 3.34 3.23 4.04C3.23 5.29 5.63 5.31 5.63 5.98C5.63 6.28 5.41 6.43 4.91 6.43C4.5 6.43 4.1 6.3 3.62 6.02L3 6.76C3.44 7.07 4.09 7.25 4.63 7.3L4.63 8Z" :fill="activeInput === 'room_point' ? 'rgb(77, 136, 249)' : '#D0D5E1'" fill-opacity="1.000000" fill-rule="nonzero"/>
                    </svg>
                        <input type="text" v-model="room_point" :placeholder="$t('input_room_point')" @click="changeInput('room_point')">
                </div>
            </div>
        </div>
        <div class="alert_buttons" >
            <button class="alert_cancle_but" type="button" @click="hideAddRoom" >{{ $t('cancel') }}</button>
            <button class="alert_ok_but" type="button" @click="addRoomAction">{{ $t('ok') }}</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      isVisible: false,
      activeInput: '',
      user_count: "10",
      room_point: "",
    };
  },
  methods: {
    showAddRoom() {
        this.isVisible = true;
    },
    async addRoomAction(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        const response = await axios.post(this.$server_url+'/api/v1/add_room', {
            user_id:JSON.parse(sessionStorage.getItem('user')).id,
            user_count:this.user_count,
            room_point: this.room_point,
        });
        if(response.data.code == 0){
            sessionStorage.setItem('room', JSON.stringify(response.data.room));
            this.isVisible = false;
            this.$parent.$refs.appSelRoom.isVisible = false;
            this.$parent.$refs.appCountRoom.isVisible = true;
            this.$parent.$refs.appCountRoom.initWebSocket(response.data.room.id);
        }
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();
    },
    hideAddRoom() {
        this.isVisible = false;
    },
    changeInput(inputName) {
        this.activeInput = inputName;
    },
  },
};
</script>
<style>
.login_inputs p{
    color: rgb(118, 128, 149) !important;
}
</style>