<template>
    <div v-if="isVisible" class="mask" style="z-index:102;" >
    </div>
    <div v-if="isVisible" class="app_alert" style="z-index:103;">
        <p class="alert_title">{{ $t('notice') }}</p>
        <img class="close_but" src="../assets/image/close.png" alt="" @click="hideNotice">
        <div class="alert_des">
            <div class="login_inputs">
                  <!-- <p class="notice_title" style="color: rgb(45, 54, 72) !important;" >{{ title }}</p> -->
                  <pre class="notice_des" style="color: rgb(45, 54, 72) !important;" v-html="description" ></pre>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      isVisible: false,
      activeInput: '',
      title:'',
      description:'',

    };
  },
  mounted() {
    
  },
  methods: {
    async getNotice(){
      // console.log(sessionStorage.getItem("IMI18n"));
      const hide = this.$loading();
      // console.log("输出检查local_host:"+this.$local_host);  // 输出检查
      // console.log("输出检查host:"+this.$server_url);  // 输出检查
      // console.log("输出检查host_name:"+this.$server_url_server);  // 输出检查
        const response = await axios.post(this.$server_url+'/api/v1/get_notice', {
          lang: sessionStorage.getItem("IMI18n"),
        });
        this.title = response.data.notice.title;
        this.description = response.data.notice.description;
        this.$closeLoading(hide);
        this.isVisible = true;
    },
    showNotice() {
        this.getNotice();
      
    },
    hideNotice() {
      this.isVisible = false;
    },
    changeInput(inputName) {
      this.activeInput = inputName;
    },
  },
};
</script>

<style>
.login_inputs p {
    color: rgb(118, 128, 149) !important;
}
.share_input {
    display: flex;
    box-sizing: border-box;
    border: 1px solid rgb(177, 189, 219);
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    width: 100%;
    height: 43px;
    padding: 5px 0px;
    padding-left: 5px;
    justify-content: space-between;
    align-items: center;
}
.alert_des {
    margin-bottom: 5%;
}
</style>
