<template>
    <div v-if="isVisible" class="mask" style="z-index:102;" >
    </div>
    <div v-if="isVisible" class="app_alert" style="z-index:103;">
        <p class="alert_title">{{ $t('drawmoney') }}</p>
        <div class="alert_des">
            <img class="coinlogo" src="../assets/image/usdtlogo.png" alt="">
            <p class="network" >{{ network }}</p>
            <div class="login_inputs">
                <div class="login_input">
                    <p>{{ $t('address') }}</p>
                    <div class="address">
                        <textarea v-model="addressValue"></textarea>
                        <img @click="showEditAddress" src="../assets/image/edit.png" style="width: 1.2rem;margin-top: 1rem;" alt="">
                    </div>
                </div>
                <p class="login_input_text" style="color: rgb(136, 136, 138) !important;" >{{ $t('confirm_withdrawal_address') }}</p>
                <div class="login_input" style="border-top: 1px dashed rgb(177, 189, 219);" >
                    <div class="flex_betwwen" >
                        <p>{{ $t('amount') }}</p>
                        <p>≈ {{ transformedValue }} USDT</p>
                    </div>
                    <div class="address">
                        <input v-model="amounValue" type="number">
                        <span class="usdt_but" @click="toMax" >USDT <span class="usdt_max"  >MAX</span></span>
                    </div>
                    <div class="flex_betwwen" >
                        <p>{{ $t('exchange') }}</p>
                        <p>≈ {{ usdtMaxExchange }} usdt</p>
                    </div>
                    <div class="flex_betwwen" >
                        <p>{{ $t('game') }}</p>
                        <p>{{ pointMaxAmount }} Point</p>
                    </div>
                </div>
                <div class="qrcode" style="margin-top: 3%;height: auto;">
                    
                    <div class="qrcode_text" >
                        <p style="font-size: 10px;" >{{ $t('outcoin_text') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert_buttons" >
            <button class="alert_cancle_but" type="button" @click="hideOutcoin" >{{ $t('cancel') }}</button>
            <button class="alert_ok_but" type="button" @click="checkPass">{{ $t('ok') }}</button>
        </div>
    </div>
    <CheckPass ref="CheckPass" />
</template>

<script>
import axios from 'axios';
import CheckPass from './CheckPass.vue';

export default {
  data() {
    return {
      isVisible: false,
      amounValue: 0,
      addressValue: "",
      network: "",
      usdtAmount: 0,
      usdtMaxExchange: 0,
      pointMaxAmount: 0,
      exchangeRate: 0,
      tron_gass: 0,
    };
  },
  components: {
    CheckPass,
  },
  computed: {
    transformedValue() {
      // 根据 inputValue 进行一些变换
      // 这里的变换可以是任何您需要的逻辑
      console.log("amounValue"+this.amounValue);
      console.log("exchangeRate"+this.exchangeRate);
      console.log("tron_gass"+this.tron_gass);
      var value = this.amounValue / this.exchangeRate-this.tron_gass;
      if(value<0  ){
        value = 0;
      }
      return value; // 示例：将输入的文本转换为大写
    },
  },
  methods: {
    showEditAddress(){
        this.$parent.$refs.AppSetAddress.showWithPass();
    },
    checkPass(){
        this.$refs.CheckPass.showWithPass();
    },
    toMax(){
        this.amounValue = JSON.parse(sessionStorage.getItem('user')).point;
    },
    async addOutAction(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        const confirmed = window.confirm(this.$t('outcoin_confirm'));
        if(confirmed){
            if(this.amounValue > JSON.parse(sessionStorage.getItem('user')).point){
                this.$alertMessage('error', this.$t('sockentError.point_over_error'));
                return;
            }
            try {
                const response = await axios.post(this.$server_url+'/user/addOutAction', {
                    user_id: JSON.parse(sessionStorage.getItem('user')).id,
                    amount:this.amounValue,
                    address:this.addressValue,
                    password:this.$refs.password,
                    withPass: this.$refs.repassword,
                });
                
                if (response.data.code == 0) {
                    sessionStorage.setItem('user', response.data.data);
                    this.hideOutcoin();
                }else{
                    this.$alertMessage('error', this.$t('password_incorrect'));
                }
            } catch (error) {
                this.$closeLoading(hide);
                this.$parent.$refs.AppLoading.hideLoading();
                // this.$alertMessage('error', this.$t('response_error'));

                // this.$parent.$refs.alertComponent.showAlert(this.$t('response_error'));
            }
        }
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();
    },
    async getAddress(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        var outcoin_pass = JSON.parse(sessionStorage.getItem('user')).outcoin_pass;
        if(outcoin_pass == null || outcoin_pass == ''){
            this.$parent.$refs.AppWithPass.showWithPass();
        }
        
        try {
            const response = await axios.post(this.$server_url+'/user/getAddress', {
                user_id: JSON.parse(sessionStorage.getItem('user')).id,
            });
            console.log(response);
            if (response.data.code == 0) {
                sessionStorage.setItem('setting',response.data.setting)
                var user = JSON.parse(sessionStorage.getItem('user'));
                this.pointMaxAmount = user.point;
                this.usdtMaxExchange = user.point / response.data.setting.exchangeRate - response.data.setting.tron_gass;
                this.network = response.data.network.name;
                this.tron_gass = response.data.setting.tron_gass;
                this.exchangeRate = response.data.setting.exchangeRate;
                this.addressValue = response.data.userCoinInfo.coinAddress;
                if(response.data.userCoinInfo.coinAddress == null || response.data.userCoinInfo.coinAddress == ''){
                    this.$parent.$refs.AppSetAddress.showWithPass();
                }
                
            }
        } catch (error) {
            this.$closeLoading(hide);
            this.$parent.$refs.AppLoading.hideLoading();
            // this.$alertMessage('error', this.$t('response_error'));

            // this.$parent.$refs.alertComponent.showAlert(this.$t('response_error'));
        }
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();
    },

    showDrawmoney() {
        this.getAddress();
        
      this.isVisible = true;
    },
    hideOutcoin() {
      this.isVisible = false;
    },
    changeInput(inputName) {
      this.activeInput = inputName;
    },
  },
};
</script>

<style scoped >
.address_but{
    background: linear-gradient(rgb(252, 217, 51), rgb(254, 174, 22));
    padding: 13px 4px;
    border-radius: 5px;
    width: 41px;
    text-align: center;
}
.coinlogo{
    width: 46px;
}
.login_inputs p {
    color: rgb(118, 128, 149) !important;
}
.share_input {
    display: flex;
    box-sizing: border-box;
    border: 1px solid rgb(177, 189, 219);
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    width: 100%;
    height: 43px;
    padding: 5px 0px;
    padding-left: 5px;
    justify-content: space-between;
    align-items: center;
}
.alert_des {
    margin-bottom: 5%;
}
.address{
    box-sizing: border-box;
    border: 1px solid rgb(177, 189, 219);
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    width: 100%;
    padding: 5px 3px;
    display: flex;
    align-items: center;
}
textarea{
    box-sizing: border-box;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    width: 100%;
    border: none;
}
.network{
    font-size: 12px;
    text-align: center;;
}
input{
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
}
p{
    margin-bottom: 0;
}
.usdt_but{
    display: flex;
    justify-content: space-between;
}
.usdt_max{
    margin-left: 5px;
    color: rgb(255, 194, 3);
}
</style>
