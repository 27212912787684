<template>
    <div class="user" >
        <div class="user_proit" @click="showUser" >
            <el-avatar v-if="!is_show_proit" :size="size" :style="{ backgroundColor: backgroundColor }">
                {{ proit }}
            </el-avatar>
            <img v-if="is_show_proit" :src="proit" alt="" srcset="">
        </div>
        <div class="user_des" @click="showUser" >
            <p class="user_nickname" >{{ nickname }}</p>
            <p class="user_point" >
                <svg width="9.000000" height="8.500000" viewBox="0 0 9 8.5" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <desc>
                            Created with Pixso.
                    </desc>
                    <defs/>
                    <path id="椭圆 12" d="M0 5.27C0 5.22 -0.01 3.39 0 3.37C0.03 1.62 2.03 2.04 4.5 2.04C6.97 2.04 8.98 1.6 9 3.37C9 3.38 9 5.26 9 5.27C9 7.05 6.98 8.5 4.5 8.5C2.01 8.5 0 7.05 0 5.27Z" fill="#E6C31B" fill-opacity="1.000000" fill-rule="evenodd"/>
                    <ellipse id="椭圆 13" cx="4.500000" cy="3.500000" rx="4.500000" ry="3.500000" fill="#FDEC9B" fill-opacity="1.000000"/>
                    <ellipse id="椭圆 13" cx="4.500000" cy="3.500000" rx="3.500000" ry="2.500000" fill="#F2D441" fill-opacity="1.000000"/>
                    <path id="矢量 30" d="M4.04 5.04L4.76 5.04L4.76 4.63C5.49 4.54 5.85 4.24 5.85 3.86C5.85 3.13 4 3.14 4 2.79C4 2.62 4.18 2.56 4.5 2.56C4.79 2.56 5.01 2.62 5.26 2.75L5.8 2.4C5.53 2.23 5.21 2.11 4.76 2.08L4.76 1.68L4.04 1.68L4.04 2.08C3.36 2.16 2.96 2.43 2.96 2.82C2.96 3.52 4.81 3.53 4.81 3.91C4.81 4.08 4.64 4.16 4.26 4.16C3.94 4.16 3.63 4.09 3.27 3.93L2.78 4.35C3.12 4.52 3.62 4.62 4.04 4.65L4.04 5.04Z" fill="#FAFBFF" fill-opacity="1.000000" fill-rule="nonzero"/>
                </svg>
                <span class="user_point_span" >
                    {{ formatNumber(point) }}
                </span>
            </p>
        </div>
        <div class="top_button" >
            <img @click="showAddRoom()" src="../../assets/image/add_room.png" alt="">
            <img @click="search()" src="../../assets/image/search.png" alt="">
            <img v-if="is_show_share" @click="shareRoom()" src="../../assets/image/share.png" alt="">
            <img v-if="is_show_share" @click="back()" src="../../assets/image/back.png" alt="">
        </div>
        <appSelRoom ref="appSelRoom" />
        <appCountRoom ref="appCountRoom" />
        <div class="buttons" >
            <div class="button recharge" >
                <img src="../../assets/image/Recharge.png" @click="showRecharge" alt="">
                <p class="but_p" >{{ $t('button_recharge') }}</p>
            </div>
            <div class="button drawmoney" >
                <img src="../../assets/image/Drawmoney.png" @click="showDrawmoney" alt="">
                <p class="but_p">{{ $t('button_drawmoney') }}</p>
            </div>
            <div class="button commission" >
                <img src="../../assets/image/Commission.png" @click="showMine" alt="">
                <p class="but_p">{{ $t('button_commission') }}</p>
            </div>
            <div class="button record" >
                <img src="../../assets/image/Record.png" @click="showRecord" alt="">
                <p class="but_p">{{ $t('button_record') }}</p>
            </div>
            <div class="button help" @click="showNotice" >
                <img src="../../assets/image/Help.png" alt="">
                <p class="but_p">{{ $t('button_help') }}</p>
            </div>
            <div class="button set" @click="showSet" >
                <img src="../../assets/image/Set.png" alt="">
                <p class="but_p">{{ $t('button_set') }}</p>
            </div>
        </div>
    </div>
    <Alert ref="alertComponent" />
    <AppAddRoom ref="AppAddRoom" />
    <AppSearchRoom ref="AppSearchRoom" />
    <AppShare ref="AppShare" />
    <AppUser ref="AppUser" />
    <AppUserPass ref="AppUserPass" />
    <AppWithPass ref="AppWithPass" />
    <AppShareRoom ref="AppShareRoom" />
    <AppUsername ref="AppUsername" />
    <AppSet ref="AppSet" />
    <AppNotice ref="AppNotice" />
    <AppRecord ref="AppRecord" />
    <Sound ref="sound" />
    <AppIncoin ref="AppIncoin" />
    <AppOutcoin ref="AppOutcoin" />
    <AppMine ref="AppMine" />
    <AppLoading ref="AppLoading" />
    <AppSetAddress ref="AppSetAddress" />
    <AppIncoinAlert ref="AppIncoinAlert" />
    <AppNoButtonAlert ref="AppNoButtonAlert" />
</template>
<script>
import { defineComponent } from 'vue';
import '../../assets/css/room.css';
import appSelRoom from './AppSelRoom.vue';
import appCountRoom from './AppCountRoom.vue';
import Alert from '../AppAlert.vue';
import AppAddRoom from '../AppAddRoom.vue';
import AppSearchRoom from '../AppSearchRoom.vue';
import AppShare from '../AppShare.vue';
import AppUser from '../AppUser.vue';
import AppUserPass from '../AppUserPass.vue';
import AppWithPass from '../AppWithPass.vue';
import AppShareRoom from '../AppShareRoom.vue';
import AppUsername from '../AppUsername.vue';
import AppSet from '../AppSet.vue';
import AppNotice from '../AppNotice.vue';
import AppRecord from '../AppRecord.vue';
import Sound from '../SoundManager.vue';  // 导入声音组件
import AppIncoin from '../AppIncoin.vue';
import AppOutcoin from '../AppOutcoin.vue';
import AppMine from '../AppMine.vue';
import AppLoading from '../AppLoading.vue';
import AppSetAddress from '../AppSetAddress.vue';
import AppIncoinAlert from '../AppIncoinAlert.vue';
import AppNoButtonAlert from '../AppNoButtonAlert.vue';
import axios from 'axios';

import { useRoute } from 'vue-router';
export default defineComponent({
    data() {
        var userData = JSON.parse(sessionStorage.getItem("user"))
        var proitObject = {}
        proitObject.backgroundColor=this.getFixedColor(userData.nickname)
        
        proitObject.proit = userData.proit == null ? userData.nickname.substring(0,1) : this.$image_url+userData.proit
        proitObject.nickname = userData.nickname
        proitObject.point = userData.point
        proitObject.is_show_proit = userData.proit == null ? false : true
        proitObject.is_show_share = false
        proitObject.room_code = ""
        return proitObject
    },
    components: {
        appSelRoom,
        Alert,
        appCountRoom,
        AppAddRoom,
        AppSearchRoom,
        AppShare,
        AppUser,
        AppUserPass,
        AppWithPass,
        AppShareRoom,
        AppUsername,
        AppSet,
        AppNotice,
        AppRecord,
        Sound,
        AppIncoin,
        AppOutcoin,
        AppMine,
        AppLoading,
        AppSetAddress,
        AppIncoinAlert,
        AppNoButtonAlert,
    },
    mounted(){
        this.$refs.sound.playAudio('bac1');
        const route = useRoute();
        this.room_code = "";
        this.room_code = route.query.room_code;
        if(this.room_code != "" && this.room_code != null){
            this.$refs.AppSearchRoom.room_number = this.room_code;
            this.$refs.AppSearchRoom.goRoomAction();
        }
        this.updateUser();
    },
    methods: {
        async updateUser(){
            
            const hide = this.$loading();
            this.$refs.AppLoading.showLoading();
            const response = await axios.post(this.$server_url+'/user/get_user', {
                userID: JSON.parse(sessionStorage.getItem('user')).id,
            });
            
            sessionStorage.setItem('user', JSON.stringify(response.data.user));
            this.point = response.data.user.point;
            this.$closeLoading(hide);
            this.$refs.AppLoading.hideLoading();
        },
        showLoading(){
            this.$refs.AppLoading.showLoading();
        },
        showMine(){
            this.$refs.AppMine.showMine();
        },
        showDrawmoney(){
            this.$refs.AppOutcoin.showDrawmoney();
        },
        showRecharge(){
            
            this.$refs.AppIncoin.showRecharge();
        },
        showRecord(){
            this.$refs.AppRecord.showRecord();
        },
        showNotice(){
            this.$refs.AppNotice.showNotice();
        },
        showSet(){
            this.$refs.AppSet.showShare();
        },
        shareRoom(){
            this.$refs.AppShareRoom.showShare();
        },
        showUser(){
            this.$refs.AppUser.showUser();
        },
        share(){
            this.$refs.AppShare.showShare();
        },
        search(){
            this.$refs.AppSearchRoom.showSearchRoom();
        },
        showAddRoom(){
            this.$refs.AppAddRoom.showAddRoom();
        },
        back(){
          this.$refs.appCountRoom.isVisible = false;
          this.$refs.appSelRoom.isVisible = true;
          this.$refs.appCountRoom.closeWebSocket();
          this.$refs.sound.playAudio('bac1');
          this.$refs.sound.pauseAudio('bac2');
        },
        getFixedColor(val) {
            // 确保输入字符串有效
            if (!val || val.length === 0) {
                return 'hsl(0, 0%, 0%)'; // 返回黑色作为默认值
            }

            // 获取字符串的第一个字符并将其转换为ASCII码
            const char = val.charAt(0).toUpperCase();  // 转换为大写以统一处理
            const ascii = char.charCodeAt(0);

            // 使用一个较大的步长映射到 0-360 的色相范围，确保颜色差异足够大
            const hue = (ascii * 39) % 360;  // 39 是一个质数，可以帮助分散颜色

            // 固定饱和度和亮度
            const saturation = 75; // 75%
            const lightness = 65;  // 65%

            // 返回HSL颜色字符串
            return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        },
        formatNumber(value) {
            if (!value) return '0';
  
  if (value >= 1_000_000_000) {
    // 十亿及以上，显示 B
    return (value / 1_000_000_000).toFixed(2) + 'B';
  } else if (value >= 1_000_000) {
    // 百万及以上，显示 M
    return (value / 1_000_000).toFixed(2) + 'M';
  } else if (value >= 1_000) {
    // 千及以上，显示 K
    return (value / 1_000).toFixed(2) + 'K';
  }
  
  // 小于 1000，返回原值
  return value.toString();
        },
    }
})
</script>

<style scoped>
el-avatar {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
p{
    margin: 0 !important;
}
*{
    box-sizing: unset !important;
}
.but_p
{
    margin-top: 2px !important;
}
</style>
