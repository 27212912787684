<template>
    <div v-if="isVisible" class="mask" style="z-index:102;" >
    </div>
    <div v-if="isVisible" class="app_alert" style="z-index:103;height: 88%;padding: 10px;display: flex;flex-direction: column;">
        <p class="alert_title">{{ $t('mine') }}</p>
        <img class="close_but" src="../assets/image/close.png" alt="" @click="hideMine">
        <div class="alert_des" style="margin: 0;flex-grow: 1;" >
            
            <div class="login_inputs" style="height: 100%;display: flex;flex-direction: column;" >
                <div class="mine_coin" >
                    <div class="coin_area" >
                        <p>{{ $t('point') }}: {{ formatNumber(point) }}</p>
                        <span class="coin_area_but" @click="goRecharge" >{{ $t('recharge') }}</span>
                    </div>
                    <div class="coin_area" style="margin-top: 5px;" >
                        <p>{{ $t('usdt') }}: {{ formatNumber(usdt) }}</p>
                        <span class="coin_area_but" @click="goDrawMoney" >{{ $t('drawmoney') }}</span>
                    </div>
                </div>
                    <div class="record_but" >
                        <p :class="incoin" @click="selIncoin" >{{ $t('recharge') }}</p>
                        <p :class="outcoin" @click="selOutcoin">{{ $t('drawmoney') }}</p>
                    </div>
                    <div class="records" style="height: 320px;" >

                        <div class="records_items" v-html="record_html" >
                            
                        </div>
                        <div class="record_buttons" >
                            <button class="record_button" @click="prevPage" >{{ $t('prev') }}</button>
                            <button class="record_button" @click="nextPage" >{{ $t('next') }}</button>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import boomImg from '../assets/image/boom.png';
export default {
  data() {
    return {
        point: JSON.parse(sessionStorage.getItem('user')).point,
        usdt: 0,
        isVisible: false,
        activeInput: '',
        incoin_page: 1,
        ourcoin_page: 1,
        record_html:"",
        incoin_totalPage: 0,
        outcoin_totalPage: 0,
        incoin:'v',
        outcoin: '',
    };
  },
  methods: {
    async getMine(){
        const response = await axios.post(this.$server_url+'/user/getSetting', {
        });
        if (response.data.code == 0) {
            this.usdt = JSON.parse(sessionStorage.getItem('user')).point / response.data.setting.exchangeRate;
        }
    },
    goDrawMoney(){
        this.hideMine();
        this.$parent.$refs.AppOutcoin.showDrawmoney();
    },
    goRecharge(){
        this.hideMine();
        this.$parent.$refs.AppIncoin.showRecharge();
    },
    selIncoin(){
        this.incoin = 'v';
        this.outcoin = '';
        this.getRechage();
    },
    selOutcoin(){
        this.incoin = '';
        this.outcoin = 'v';
        this.getDrawMoney();
    },
    formatTimestamp(timestamp) {
        // 将时间戳转换为毫秒
        var date = new Date(timestamp * 1000);

        // 获取年份、月份、日期、小时、分钟和秒
        var year = date.getFullYear();
        var month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
        var day = String(date.getDate()).padStart(2, '0');
        var hours = String(date.getHours()).padStart(2, '0');
        var minutes = String(date.getMinutes()).padStart(2, '0');
        var seconds = String(date.getSeconds()).padStart(2, '0');

        // 格式化为 YYYY.MM.DD HH:MM:SS
        return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
    },
    nextPage(){
        if(this.incoin == 'v'){
            if(this.incoin_page<=this.incoin_totalPage){
                this.incoin_page ++;
                this.getRechage();
            }
        }else{
            if(this.ourcoin_page<=this.outcoin_totalPage){
                this.ourcoin_page ++;
                this.getDrawMoney();
            }
        }
    },
    prevPage(){
        if(this.incoin == 'v'){
            if(this.incoin_page > 1){
                this.incoin_page --;
                this.getRechage();
            }
        }else{
            if(this.ourcoin_page > 1){
                this.ourcoin_page --;
                this.getDrawMoney();
            }
        }
    },
    async getRechage(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        const response = await axios.post(this.$server_url+'/user/getRecharge', {
            page: this.incoin_page,
            userID: JSON.parse(sessionStorage.getItem('user')).id,
        });
        var html = '';
        var coinFlows = response.data.coinWalletFlows;
        var that = this;
        coinFlows.forEach(function(flow) {
            var time = that.formatTimestamp(flow.addtime);
            var point = flow.amount;
            
            html += `<div class="record_once" >
                            <p class="record_time" >`+time+`</p>
                            <p style="color: rgb(255, 116, 3) !important;" >`+point+`</p>
                        </div>`;
        });
        this.record_html = html;
        this.$parent.$refs.AppLoading.hideLoading();
        this.$closeLoading(hide);
    },
    async getDrawMoney(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        const response = await axios.post(this.$server_url+'/user/getDrawMoney', {
            page: this.ourcoin_page,
            userID: JSON.parse(sessionStorage.getItem('user')).id,
        });
        var html = '';
        var coinFlows = response.data.coinWalletFlows;
        var that = this;
        coinFlows.forEach(function(flow) {
            var time = that.formatTimestamp(flow.addtime);
            var point = -flow.amount;
            
            html += `<div class="record_once" >
                            <p class="record_time" >`+time+`</p>
                            <p style="color: rgb(255, 116, 3) !important;" >`+point+`</p>
                        </div>`;
        });
        this.record_html = html;
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();
    },

    showMine() {
        this.getMine();
        this.getRechage();
      this.isVisible = true;
    },
    hideMine() {
      this.isVisible = false;
    },
    changeInput(inputName) {
      this.activeInput = inputName;
    },
    formatNumber(value) {
        if (!value) return '0';
  
  if (value >= 1_000_000_000) {
    // 十亿及以上，显示 B
    return (value / 1_000_000_000).toFixed(2) + 'B';
  } else if (value >= 1_000_000) {
    // 百万及以上，显示 M
    return (value / 1_000_000).toFixed(2) + 'M';
  } else if (value >= 1_000) {
    // 千及以上，显示 K
    return (value / 1_000).toFixed(2) + 'K';
  }
  
  // 小于 1000，返回原值
  return value.toString();
        },
  },
};
</script>

<style>
.coin_area_but{
    background: linear-gradient(rgb(252, 217, 51), rgb(254, 174, 22));
    border-radius: 5px;
    width: 78px;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mine_coin{
    margin-bottom: 10px;
    background: white;
    padding: 10px;
    border-radius: 5px;
}
.coin_area{
    display: flex;
    justify-content: space-between;
}
.alert_des {
    margin-bottom: 5%;
}
.record_but p::after {
    content: "";
    position: absolute;
    /* width: 30px; */
    border-bottom: 3px solid rgb(177, 189, 219);
    /* margin-top: 25px; */
}
p{
    margin: 0;
}
</style>
