<template>
    <div v-if="isVisible" class="mask" style="z-index:102;" >
    </div>
    <div v-if="isVisible" class="app_alert" style="z-index:103;height: 88%;padding: 10px;display: flex;flex-direction: column;">
        <p class="alert_title">{{ $t('record') }}</p>
        <img class="close_but" src="../assets/image/close.png" alt="" @click="hideRecord">
        <div class="alert_des" style="margin: 0;flex-grow: 1;" >
            <div class="login_inputs" style="height: 100%;display: flex;flex-direction: column;" >
                    <div class="record_but" >
                        <p :class="me" @click="selMe" >{{ $t('person')}}</p>
                        <p :class="person" @click="selPerson">{{ $t('offline') }}</p>
                    </div>
                    <div class="records" >

                        <div class="records_items" v-html="record_html" >
                            
                        </div>
                        <div class="record_buttons" >
                            <button class="record_button" @click="prevPage" >{{ $t('prev') }}</button>
                            <button class="record_button" @click="nextPage" >{{ $t('next') }}</button>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import boomImg from '../assets/image/boom.png';
export default {
  data() {
    return {
        isVisible: false,
        activeInput: '',
        round_flow_page: 1,
        coin_flow_page: 1,
        record_html:"",
        round_flow_totalPage: 0,
        coin_flow_totalPage: 0,
        me:'v',
        person: '',
    };
  },
  mounted() {
    this.getRoundFlow();
  },
  methods: {
    selMe(){
        this.me = 'v';
        this.person = '';
        this.getRoundFlow();
    },
    selPerson(){
        this.me = '';
        this.person = 'v';
        this.getCoinFlow();
    },
    formatTimestamp(timestamp) {
        // 将时间戳转换为毫秒
        var date = new Date(timestamp * 1000);

        // 获取年份、月份、日期、小时、分钟和秒
        var year = date.getFullYear();
        var month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
        var day = String(date.getDate()).padStart(2, '0');
        var hours = String(date.getHours()).padStart(2, '0');
        var minutes = String(date.getMinutes()).padStart(2, '0');
        var seconds = String(date.getSeconds()).padStart(2, '0');

        // 格式化为 YYYY.MM.DD HH:MM:SS
        return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
    },
    nextPage(){
        if(this.me == 'v'){
            if(this.round_flow_page<=this.round_flow_totalPage){
                this.round_flow_page ++;
                this.getRoundFlow();
            }
        }else{
            if(this.coin_flow_page<=this.coin_flow_totalPage){
                this.coin_flow_page ++;
                this.getCoinFlow();
            }
        }
    },
    prevPage(){
        if(this.me == 'v'){
            if(this.round_flow_page > 1){
                this.round_flow_page --;
                this.getRoundFlow();
            }
        }else{
            if(this.coin_flow_page > 1){
                this.coin_flow_page --;
                this.getCoinFlow();
            }
        }
    },
    async getCoinFlow(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        const response = await axios.post(this.$server_url+'/user/get_coin_flow', {
            page: this.coin_flow_page,
            userID: JSON.parse(sessionStorage.getItem('user')).id,
        });
        var html = '';
        var coinFlows = response.data.coinFlows;
        var that = this;
        coinFlows.forEach(function(flow) {
            var time = that.formatTimestamp(flow.addtime);
            
            html += `<div class="record_once" >
                            <p>`+flow.nickname+`</p>
                            <p class="record_time" >`+time+`</p>
                            <p style="color: rgb(255, 116, 3) !important;" >`+flow.point+`</p>
                        </div>`;
        });
        this.record_html = html;
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();
    },
    async getRoundFlow(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        const response = await axios.post(this.$server_url+'/user/get_round_flow', {
            page: this.round_flow_page,
            userID: JSON.parse(sessionStorage.getItem('user')).id,
        });
        var html = '';
        var roundFlows = response.data.roundFlows;
        var that = this;
        roundFlows.forEach(function(flow) {
            var time = that.formatTimestamp(flow.endtime);
            var color = "";
            var price = 0;
            var img = '';
            that.round_flow_totalPage = response.data.totalPage;
            
            if(flow.result = "0"){
                price = flow.total_price;
                color = "rgb(255, 116, 3)";
            }else{
                color = "rgb(11, 201, 64)";
                price = flow.total_price*-1;
                img = `<img src="${boomImg}" alt="">`;
            }
            html += `<div class="record_once" >
                            <p class="record_time" >`+time+`</p>
                            <p style="color: `+color+` !important;" >`+price+` `+img+`</p>
                        </div>`;
        });
        this.record_html = html;
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();
    },

    showRecord() {
      this.isVisible = true;
    },
    hideRecord() {
      this.isVisible = false;
    },
    changeInput(inputName) {
      this.activeInput = inputName;
    },
  },
};
</script>

<style>
.alert_des {
    margin-bottom: 5%;
}
p{
    margin: 0;
}
</style>
