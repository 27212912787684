<template>
    <div v-if="isVisible" class="mask" style="z-index:102;" >
    </div>
    <div v-if="isVisible" class="app_alert" style="z-index:103;">
        <p class="alert_title">{{ $t('with_pass') }}</p>
        <div class="alert_des">
            <div class="login_inputs">
                <div class="login_input">
                    <p>{{ $t('input_nickname') }}</p>
                    <div>
                        <svg class="left_icon" :class="{ active: activeInput === 'nicname' }" width="10.000000" height="11.503784" viewBox="0 0 10 11.5038" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>Created with Pixso.</desc>
                            <defs/>
                            <g style="mix-blend-mode:normal">
                                <path id="icon/user" d="M5 0C3.34314 0 2 1.34314 2 3C2 4.65686 3.34314 6 5 6C6.65686 6 8 4.65686 8 3C8 1.34314 6.65686 0 5 0ZM5 1C6.10461 1 7 1.89542 7 3C7 4.10458 6.10461 5 5 5C3.89539 5 3 4.10458 3 3C3 1.89542 3.89539 1 5 1ZM8.87634 7.50388L8.88574 7.50388L9.45654 7.79001C9.79016 7.96121 10 8.30472 10 8.67975L10 10.5038C10 11.0561 9.55225 11.5038 9 11.5038L1 11.5038C0.447754 11.5038 0 11.0561 0 10.5038L0 8.68005C0 8.30499 0.209839 7.96149 0.543579 7.79031L1.1217 7.50388L1.12366 7.50388C2.27197 6.86658 3.59363 6.50366 5 6.50366C6.40637 6.50366 7.72803 6.86658 8.87634 7.50388ZM1 8.75836L1 10.5032L9 10.5032L9 8.75836C7.86621 7.9675 6.4873 7.50366 5 7.50366C3.5127 7.50366 2.13379 7.9675 1 8.75836Z" clip-rule="evenodd" :fill="activeInput === 'nicname' ? 'rgb(77, 136, 249)' : '#8995B4'" fill-opacity="1.000000" fill-rule="evenodd"/>
                            </g>
                        </svg>
                        <input type="text" v-model="nickname" id="nickname" :placeholder="$t('input_nicname_placeholder')" @click="changeInput('username')">
                    </div>
                </div>
            </div>
        </div>
        <div class="alert_buttons" >
            <button class="alert_cancle_but" type="button" @click="hideWithPass" >{{ $t('cancel') }}</button>
            <button class="alert_ok_but" type="button" @click="editAction">{{ $t('ok') }}</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      isVisible: false,
      activeInput: '',
      nickname: JSON.parse(sessionStorage.getItem('user')).nickname,
    };
  },
  computed: {

    },
  methods: {

    showWithPass() {
        this.isVisible = true;
    },
    async editAction(){
        const hide = this.$loading();
        this.$parent.$refs.AppLoading.showLoading();
        const response = await axios.post(this.$server_url+'/user/change_nickname', {
            id: JSON.parse(sessionStorage.getItem('user')).id,
            nickname: this.nickname,
        });
        if(response.data.code == 0){
            sessionStorage.setItem('user', response.data.data);
            this.$parent.$refs.AppUser.nickname = JSON.parse(sessionStorage.getItem('user')).nickname;
            this.$parent.nickname = JSON.parse(sessionStorage.getItem('user')).nickname;
            this.hideWithPass();
        }else{
            this.$alertMessage('error', this.$t('none_room_error'));

            // this.$parent.$refs.alertComponent.showAlert(this.$t('none_room_error'));
        }
        this.$closeLoading(hide);
        this.$parent.$refs.AppLoading.hideLoading();
    },
    hideWithPass() {
        this.isVisible = false;
    },
    changeInput(inputName) {
        this.activeInput = inputName;
    },
  },
};
</script>
<style>
.login_inputs p{
    color: rgb(118, 128, 149) !important;
}
</style>