import { createRouter, createWebHistory } from 'vue-router';
import Room from '../views/game/AppRoom.vue';
import Login from '../views/AppLogin.vue';
import Regist from '../views/AppRegist.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  { path: '/', component: Room, meta: { requiresAuth: true } },
  { path: '/login', component: Login },
  { path: '/regist', component: Regist },
  { path: '/:pathMatch(.*)*', component: NotFound }  // 404 路由
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 判断设备类型是否为手机
function isMobileDevice() {
  return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
}

// 全局前置守卫
// router.beforeEach((to, from, next) => {
//   // 检查目标路由是否需要认证
//   const isAuthenticated = !!sessionStorage.getItem('user');  // 简单示例：检查 sessionStorage 中是否有用户信息
  
//   // 电脑设备访问时自动跳转到 404 页面
//   if (!isMobileDevice() && to.path !== '/:pathMatch(.*)*') {
//     // 如果是电脑设备并且访问的路径不是 404，跳转到 404 页面
//     next({ path: '/:pathMatch(.*)*' });
//   } else {
//     // 检查目标路由是否需要认证
//     if (to.meta.requiresAuth && !isAuthenticated) {
//       // console.log("没有登入-进入到登录页面");
//       // 如果目标路由需要认证但用户未登录，重定向到登录页
//       next('/login');
//     } else {
//       // 否则，继续导航
//       next();
//     }
//   }
// });
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!sessionStorage.getItem('user');  // 检查用户是否已认证

  // 判断设备是否为移动设备
  if (!isMobileDevice()) {
    // 如果是电脑设备，所有访问都重定向到 404 页面
    next({ path: '/:pathMatch(.*)*' });
  } else {
    // 如果已经登录，阻止访问 login 和 regist 页面，重定向到首页
    if (isAuthenticated && (to.path === '/login' || to.path === '/regist')) {
      next({ path: '/' });
    } else if (to.meta.requiresAuth && !isAuthenticated) {
      // 如果目标路由需要认证且用户未登录，重定向到登录页面
      next('/login');
    } else {
      // 否则，继续导航
      next();
    }
  }
});

export default router;
